import * as React from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { Button, Modal, Descriptions, Table, Alert } from 'antd';
import {
  CaretLeftOutlined,
  CodeOutlined,
  MinusSquareTwoTone,
  PartitionOutlined,
  PlusSquareTwoTone,
  WindowsOutlined,
} from '@ant-design/icons';
import {
  CreateMultiTargetRequest,
  defaultMultiTargetDetailViewDto,
  MultiTargetDetailViewDto,
  MultiTargetRequest,
} from '../ts-models';

import { useState } from 'react';

import { useNavigate, useParams } from 'react-router';

import styled from 'styled-components';
import { DateTime } from 'luxon';
import { useData } from '../generic/glow/actions/use-data';
import { useSubmit } from '../generic/glow/actions/use-submit';

function MultiTargetManage({
  setIntervalCounter,
  isAdd,
}: {
  setIntervalCounter: (count: number) => void;
  isAdd: boolean;
}) {
  const { id } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    string | undefined
  >(undefined);

  const [submit, validate] = useSubmit<MultiTargetRequest>(
    `/api/multi-target-request/create`,
  );
  const navigate = useNavigate();

  let handleCancel = () => {
    setIsModalVisible(false);
  };
  let handleOk = async () => {
    let payload: CreateMultiTargetRequest = {
      isDeployRequest: isAdd,
      multiTargetId: id || '',
    };

    let result = await submit(payload);
    if (result.ok) {
      setIsModalVisible(false);
      // notification.success({
      //   duration: 4,
      //   message: "Countries are processed in background.",
      // })
      navigate(`/multi-target/request/${result.payload.id}/${id}`);
    } else {
      setSubmitErrorMessage(JSON.stringify(result.error));
    }
  };

  return (
    <React.Fragment>
      {isAdd ? (
        <Button
          key="1"
          icon={<PlusSquareTwoTone twoToneColor="#52c41a" />}
          size="middle"
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          New deploy request
        </Button>
      ) : (
        <Button
          key="2"
          icon={<MinusSquareTwoTone twoToneColor="#ff4d4f" />}
          size="middle"
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          New removal request
        </Button>
      )}
      <Modal
        title={isAdd ? 'New deploy request' : 'New removal request'}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {submitErrorMessage && (
          <Alert
            message="Error"
            type="error"
            showIcon
            description={submitErrorMessage}
          />
        )}
        Do you want to create a new deployment request?
      </Modal>
    </React.Fragment>
  );
}

export function MultiTargetDetailView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [intervalNumber, setIntervalNumber] = useState<number | null>(null);
  const [intervalCounter, setIntervalCounter] = useState<number>(0);
  const [intervalState, setIntervalState] = useState<'enabled' | 'disabled'>(
    'disabled',
  );

  const {
    data: entity,
    refetch: refetchDetailview,
    status,
  } = useData<MultiTargetDetailViewDto>(
    `/api/multi-target/${id}`,
    defaultMultiTargetDetailViewDto,
  );

  function startRefetchInterval() {
    let interval = setInterval(async () => {
      refetchDetailview();
      setIntervalCounter((prevTime) => prevTime - 1);
    }, 3000);
    return interval;
  }

  return (
    <div>
      <HeaderTop
        title={
          <div>
            <span>
              <PartitionOutlined /> Multi Target
            </span>
            <Button
              icon={<CaretLeftOutlined />}
              onClick={() => navigate('..')}
              style={{ marginLeft: 10, marginRight: 10 }}
              size="small"
            />{' '}
            {entity.displayName}
          </div>
        }
        extra={[
          <MultiTargetManage
            key="add"
            setIntervalCounter={setIntervalCounter}
            isAdd={true}
          />,
          <MultiTargetManage
            key="remove"
            setIntervalCounter={setIntervalCounter}
            isAdd={false}
          />,
        ]}
      ></HeaderTop>
      <Container>
        {/* {JSON.stringify({ intervalNumber, intervalCounter })} */}

        <Descriptions
          size="small"
          column={2}
          bordered={true}

          //bordered={false}
          //layout="vertical"
        >
          <Descriptions.Item label={`Application details`} span={2}>
            {entity.genericIntegration.type == 'Win10ClassicIntegration' ? (
              <span>
                <a
                  href={`/integrations/win10-classic/${entity.genericIntegration.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {entity.displayName}
                </a>
              </span>
            ) : (
              <span>
                <a
                  href={`/integrations/win10-app/${entity.genericIntegration.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {entity.displayName}
                </a>
              </span>
            )}
          </Descriptions.Item>
          <Descriptions.Item label={`Type`} span={2}>
            {entity.genericIntegration.type == 'Win10ClassicIntegration' ? (
              <span>
                <CodeOutlined /> Windows Desktop Application
              </span>
            ) : (
              <span>
                <WindowsOutlined /> Windows App
              </span>
            )}
          </Descriptions.Item>
          <Descriptions.Item label={`RequestId`} span={2}>
            {entity.genericIntegration.requestID}
          </Descriptions.Item>
          <Descriptions.Item label="App Owner" span={2}>
            {entity.genericIntegration?.appOwner?.displayName || 'unknown'}
          </Descriptions.Item>
        </Descriptions>

        <h3 style={{ marginTop: 20 }}>Requests history</h3>

        <RequestTable
          multiTargetRequest={entity.multiTargetRequests}
          multiTargetId={entity.id}
        />
      </Container>
    </div>
  );
}

function RequestTable({
  multiTargetRequest,
  multiTargetId,
}: {
  multiTargetRequest: MultiTargetRequest[];
  multiTargetId: string;
}) {
  const navigate = useNavigate();

  return (
    <Table<MultiTargetRequest>
      bordered={false}
      size="small"
      style={{ flex: 1 }}
      rowKey={(row) => row.id}
      onRow={(record, recordIndex) => ({
        onClick: () => {
          navigate(`/multi-target/request/${record.id}/${multiTargetId}`);
        },
      })}
      columns={[
        {
          title: 'Id',
          key: 'id',
          render: (record: MultiTargetRequest) => (
            <span>{record.requestNumber}</span>
          ),
        },
        {
          title: 'Requester',
          key: 'createdBy',
          render: (record: MultiTargetRequest) => (
            <span>{record.createdBy}</span>
          ),
        },

        {
          title: 'Insert Date (UTC)',
          key: 'createdAt',
          render: (record: MultiTargetRequest) => (
            <span>
              {DateTime.fromISO(record.createdAt).toLocaleString(
                DateTime.DATETIME_SHORT,
              )}
            </span>
          ),
        },
        {
          title: 'Status',
          key: 'status',
          render: (record: MultiTargetRequest) => (
            <span>{record.workflowInstance?.currentStateName}</span>
          ),
        },
        {
          title: 'Workflow Name',
          key: 'isDeployRequest',
          render: (record: MultiTargetRequest) => (
            <span>
              {record.isDeployRequest ? 'Deploy' : record.removalType}
            </span>
          ),
        },
      ]}
      dataSource={multiTargetRequest ? multiTargetRequest : []}
      pagination={
        multiTargetRequest && multiTargetRequest.length > 10
          ? {
              pageSize: 10,
            }
          : false
      }
    />
  );
}

const Header = styled(PageHeader)`
  padding: 0px;
  margin-bottom: 20px;
`;

const Container = styled.div`
  padding: 20px;
`;

const HeaderTop = styled(PageHeader)`
  background: #fff;
`;
