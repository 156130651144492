import * as React from 'react';
import {
  CodeOutlined,
  GlobalOutlined,
  WindowsOutlined,
} from '@ant-design/icons';

import { Country, MultiTargetListDto } from '../ts-models';

import { Column } from 'react-table';
import { Table } from '../generic/table/table';

export function MultiTargetListDefault({
  urlPredefinedFilter,
}: {
  urlPredefinedFilter: string;
}) {
  const urlPrefix = `/api/multi-target/list`;

  const columns = [
    {
      Header: 'Manufacturer',
      accessor: 'genericIntegration.manufacturer',
      disableSortBy: true,
      //Cell: ({ value }) => value || "?",
    },
    {
      Header: 'Product Name',
      accessor: 'genericIntegration.productname',
      disableSortBy: true,
      //Cell: ({ value }) => value || "?",
    },
    {
      Header: 'Product Version',
      accessor: 'genericIntegration.productVersion',
      disableSortBy: true,
      //Cell: ({ value }) => value || "?",
    },
    {
      Header: 'Request Id',
      accessor: 'genericIntegration.requestID',
      disableSortBy: true,
    },
    {
      Header: 'Language',
      accessor: 'genericIntegration.language.isoCode',
      disableSortBy: true,
      Cell: ({ value }: { value: string }) => value || 'n/a',
    },
    {
      Header: 'Architecture',
      accessor: 'genericIntegration.architecture',
      disableSortBy: true,
      Cell: ({ value }: { value: string }) => value || 'n/a',
    },

    {
      Header: 'Platform',
      accessor: 'genericIntegration.type',
      disableSortBy: true,
      Cell: ({ value }: { value: string }) => (
        <span>
          {value == 'Win10ClassicIntegration' ? (
            <span>
              <CodeOutlined /> Windows Desktop Application
            </span>
          ) : (
            <span>
              <WindowsOutlined /> Windows App
            </span>
          )}
        </span>
      ),
    },

    {
      Header: 'Status',
      accessor: 'genericIntegration.workflowInstance.currentStateName',
      disableSortBy: true,
    },
    {
      Header: 'Requests',
      accessor: 'requestCount',
      disableSortBy: true,
    },
    {
      Header: 'Country Count',
      id: 'country-count',
      accessor: 'countries',
      disableSortBy: true,
      Cell: ({ value }: { value: Country[] }) => (
        <span>
          {value.length == 63 ? (
            <span>
              <GlobalOutlined style={{ color: '#058137' }} /> {value.length}{' '}
            </span>
          ) : (
            value.length
          )}
        </span>
      ),
    },
    {
      Header: 'Assigned countries',
      id: 'country-list',
      disableSortBy: true,
      accessor: 'countries',
      Cell: ({ value }: { value: Country[] }) => (
        <span>
          {value.map((country) => {
            return country.isoCode + ', ';
          })}
        </span>
      ),
    },
  ] as Column<MultiTargetListDto>[];

  return (
    <Table<MultiTargetListDto>
      columns={columns}
      urlPrefix={
        urlPrefix +
        `?urlPredefinedFilter=${urlPredefinedFilter ? urlPredefinedFilter : ''}`
      }
      rowLink={(row) => `./${row.id}`}
    />
  );
}
