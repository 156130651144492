import * as React from "react"

import { MultiTargetRequest } from "../ts-models"

import { DateTime } from "luxon"
import { Column } from "react-table"
import { Table } from "../generic/table/table"

export function MultiTargetListByRequest({
  urlPredefinedFilter,
}: {
  urlPredefinedFilter: string
}) {
  const urlPrefix = `/api/multi-target/listByRequest`

  const columns = [
    {
      Header: "Id",
      accessor: "requestNumber",
      disableSortBy: false,
      maxWidth: 80,
      //Cell: ({ value }) => value || "?",
    },
    {
      Header: "Package",
      accessor: "multiTarget.genericIntegration.displayName",
      disableSortBy: true,

      minWidth: 350,
      //Cell: ({ value }) => value || "?",
    },
    {
      Header: "Insert Date",
      accessor: "createdAt",
      disableSortBy: false,
      Cell: ({ value }) =>
        DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT),
    },
    {
      Header: "Type",
      accessor: "isDeployRequest",
      disableSortBy: false,
      maxWidth: 100,
      Cell: ({ value }: { value: boolean }) => (value ? "Add" : "Removal"),
    },
    {
      Header: "Status",
      accessor: "workflowInstance.currentStateName",
      disableSortBy: true,
    },
  ] as Column<MultiTargetRequest>[]

  return (
    <Table<MultiTargetRequest>
      columns={columns}
      urlPrefix={
        urlPrefix +
        `?urlPredefinedFilter=${urlPredefinedFilter ? urlPredefinedFilter : ""}`
      }
      rowLink={(row) => `/multi-target/request/${row.id}/${row.multiTargetId}`}
    />
  )
}
