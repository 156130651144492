import * as React from 'react';
import { Spin, Select as SelectAntd, notification } from 'antd';
import { useField } from 'formik';

import { useState } from 'react';

import debounce from 'lodash.debounce';
import { SelectProps } from 'antd/lib/select';
import { useData } from './glow/actions/use-data';

interface RemoteSelectDto {
  id: string;
  displayName: string;
}

export function SelectFromApi({
  url,
  name,
  backingField,
  referenceId,
  ...restProps
}: {
  url: string;
  name: string;
  referenceId?: string;
  backingField: string;
} & SelectProps<any>) {
  const [searchString, setSearchString] = useState<string>('');

  const { data, status } = useData<RemoteSelectDto[]>(
    `${url.replace(
      '{id}',
      referenceId || 'referenceIdNotSet',
    )}/list?search=${searchString}`,
    [],
  );

  const [selectedItem, setselectedItem] =
    React.useState<null | RemoteSelectDto>(null);

  const [field, , { setValue, setTouched }] = useField(
    backingField ? backingField : name,
  );

  //console.log('backingField', backingField, 'name', name);

  React.useEffect(() => {
    if (field.value) {
      fetch(`${url.replace('/{id}', '')}/${field.value}`)
        .then((v) => v.json())
        .then((v) => setselectedItem(v))
        .catch((e) => notification.error({ message: e.toString() }));
    }
  }, [field.value]);

  const dataSource = React.useMemo(
    () =>
      field.value && data.some((v) => v.id == field.value)
        ? data
        : selectedItem
        ? [selectedItem, ...data]
        : data,
    [selectedItem, field.value, data],
  );

  const debouncedSearch = debounce((search) => {
    setSearchString(search);
  }, 300);
  return (
    <div>
      <SelectAntd
        allowClear={true}
        labelInValue
        loading={status == 'loading'}
        value={
          selectedItem
            ? { label: selectedItem.displayName!, value: selectedItem.id! }
            : undefined
        }
        placeholder={status == 'success' ? 'Type here to search ...' : ''}
        notFoundContent={status == 'loading' ? <Spin size="small" /> : null}
        filterOption={false}
        onChange={(value: any) => {
          console.log('value', value);
          setValue(value?.value || undefined);
        }}
        onSearch={async (search: any) => {
          debouncedSearch(search);
        }}
        showSearch={true}
        onBlur={() => {
          setTouched(true);
        }}
        style={{ width: '100%' }}
        {...restProps}
      >
        {dataSource.map((d) => (
          <SelectAntd.Option key={d.id || ''} value={d.id || ''}>
            {d.displayName}
          </SelectAntd.Option>
        ))}
      </SelectAntd>
    </div>
  );
}
