import * as React from 'react';

import { AadUser } from '../ts-models';

import { CellProps, Column, Row as RowReactTable } from 'react-table';
import { Table } from '../generic/table/table_antd';
import { Badge, Button, Checkbox, Col, Empty, Modal, Row } from 'antd';
import { ColumnType } from 'antd/es/table';
import { useParams } from 'react-router';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

export function OperationsIntuneListUsers({
  urlPredefinedFilter,
  setSelectedUsers,
  selectedUsers,
}: {
  urlPredefinedFilter: string;
  setSelectedUsers: React.Dispatch<React.SetStateAction<AadUser[]>>;
  selectedUsers: AadUser[];
}) {
  const { scope } = useParams();
  const urlPrefix = `/api/operations/users?scope=${scope}`;

  const columns = [
    {
      Header: (
        <MultiSelectHandler
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
        />
      ),
      accessor: 'selection',
      enableResizing: false,
      disableSortBy: true,
      Cell: ({ value, row }: { value: any; row: RowReactTable<AadUser> }) => (
        <Checkbox
          onChange={(e: CheckboxChangeEvent) =>
            setSelectedUsers((oldVal) =>
              e.target.checked
                ? [...oldVal, row.original]
                : [...oldVal.filter((x) => x.id !== row.original.id)],
            )
          }
          checked={
            selectedUsers?.find((x) => x.id === row.original.id) !== undefined
          }
        ></Checkbox>
      ),
      width: 50,
    },
    {
      Header: 'DisplayName',
      accessor: 'displayName',
      disableSortBy: true,
      Cell: ({ value, row }) => (
        <a
          href={`https://endpoint.microsoft.com/#view/Microsoft_AAD_UsersAndTenants/UserProfileMenuBlade/~/overview/userId/${row.original.id}`}
          target="_blank"
        >
          {value}
        </a>
      ),
      width: 300,
    },
    {
      Header: 'UserPrincipalName',
      accessor: 'userPrincipalName',
      disableSortBy: true,
      width: 350,
      Cell: ({ value }) => value || 'n/a',
    },
    {
      Header: 'OnPremisesSamAccountName',
      accessor: 'onPremisesSamAccountName',
      width: 250,
      disableSortBy: true,
      Cell: ({ value }) => value || 'n/a',
    },
  ] as Column<AadUser>[];

  return (
    <Table<AadUser>
      rowSelectionCallback={setSelectedUsers}
      rowSelectedKeys={selectedUsers?.map((user) => user.id!) || undefined}
      columns={columns}
      urlPrefix={urlPrefix}
      rowLink={undefined}
      searchPlaceHolder={'Search in all fields (startsWith)'}
      selectType="Multi"
    />
  );
}

function MultiSelectHandler({
  setSelectedUsers,
  selectedUsers,
}: {
  setSelectedUsers: React.Dispatch<React.SetStateAction<AadUser[]>>;
  selectedUsers: AadUser[];
}) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {selectedUsers.length > 0 && (
        <a onClick={showModal}>
          <Badge
            style={{ marginLeft: -4 }}
            color="blue"
            count={selectedUsers.length}
          ></Badge>
        </a>
      )}
      <Modal
        title="Selected users"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width="60%"
      >
        <SelectedUsersHandler
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
        />
      </Modal>
    </div>
  );
}

export function SelectedUsersHandler({
  setSelectedUsers,
  selectedUsers,
}: {
  setSelectedUsers: React.Dispatch<React.SetStateAction<AadUser[]>>;
  selectedUsers: AadUser[];
}) {
  return (
    <>
      {selectedUsers.map((selectedUser, i) => (
        <Row
          style={{
            backgroundColor: i % 2 !== 0 ? '#efefef' : undefined,
            marginBottom: 1,
            padding: 2,
          }}
        >
          <Col span={8}>
            <Checkbox
              onChange={(e: CheckboxChangeEvent) =>
                setSelectedUsers(
                  e.target.checked
                    ? [...selectedUsers, selectedUser]
                    : selectedUsers.filter((x) => x.id !== selectedUser.id),
                )
              }
              checked={true}
            >
              {selectedUser.displayName}
            </Checkbox>
          </Col>
          <Col span={16}>{selectedUser.userPrincipalName}</Col>
        </Row>
      ))}
      {selectedUsers.length == 0 && (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
}
