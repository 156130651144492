import * as React from "react"
import { useState } from "react"
import { useNavigate, useParams } from "react-router"
import { DeleteWin10AppIntegration } from "../ts-models"
import { Button, Modal, notification } from "antd"
import { DeleteOutlined } from "@ant-design/icons"
import { useCurrentWorkflowState } from "./workflow"
import { useSubmit } from "./glow/actions/use-submit"

export function Delete({
  baseUrl,
  navigateAfterDeleteAction,
}: {
  baseUrl: string
  navigateAfterDeleteAction?: () => void
}) {
  const { id } = useParams()
  const navigate = useNavigate()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const state = useCurrentWorkflowState()

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    performDelete()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const [submitRunAction] = useSubmit<DeleteWin10AppIntegration>(
    `${baseUrl}/delete`,
  )

  const performDelete = async () => {
    setLoading(true)
    let result = await submitRunAction({ id })

    if (result.ok) {
      setIsModalVisible(false)
      navigateAfterDeleteAction ? navigateAfterDeleteAction() : navigate("..")
      notification.success({ message: "Entry was deleted successfuly." })
    } else {
      setIsModalVisible(false)
      notification.error({ message: JSON.stringify(result.error) })
    }
  }

  return (
    (<div>
      <Modal
        title="Are you sure to delete?"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            loading={loading}
          >
            Ok
          </Button>,
        ]}
      >
        <p>Are you sure to delete this entry?</p>
      </Modal>
      {state?.deleteEnabled && (
        <Button
          icon={<DeleteOutlined />}
          type="link"
          onClick={() => {
            showModal()
          }}
        >
          Delete
        </Button>
      )}
    </div>)
  );
}
