import * as React from 'react';

import {
  AadUser,
  AzureAdDevice,
  defaultManagedDevice,
  ManagedDevice,
} from '../ts-models';

import { Table } from '../generic/table/table_antd';
import { ColumnType } from 'antd/es/table';
import { useParams } from 'react-router';
import { QueryResult } from '../ts-models-custom';
import { useData } from '../generic/glow/actions/use-data';
import { Badge, Col, Empty, Modal, Row, Skeleton } from 'antd';
import { Column, Row as RowReactTable } from 'react-table';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';

export function OperationsIntuneListManagedDevice({
  urlPredefinedFilter,
  setSelectedAzureAdDevices,
  selectedAzureAdDevices,
}: {
  urlPredefinedFilter: string;
  setSelectedAzureAdDevices: React.Dispatch<
    React.SetStateAction<AzureAdDevice[] | undefined>
  >;
  selectedAzureAdDevices: AzureAdDevice[];
}) {
  const { scope } = useParams();
  const urlPrefix = `/api/operations/manageddevices?scope=${scope}`;

  const columns = [
    {
      Header: (
        <MultiSelectHandler
          selectedAzureAdDevices={selectedAzureAdDevices}
          setSelectedAzureAdDevices={setSelectedAzureAdDevices}
        />
      ),
      accessor: '´selection',
      disableSortBy: true,
      Cell: ({
        value,
        row,
      }: {
        value: any;
        row: RowReactTable<AzureAdDevice>;
      }) => (
        <Checkbox
          onChange={(e: CheckboxChangeEvent) =>
            setSelectedAzureAdDevices(
              e.target.checked
                ? [...selectedAzureAdDevices, row.original]
                : selectedAzureAdDevices.filter(
                    (x) => x.id !== row.original.id,
                  ),
            )
          }
          checked={
            selectedAzureAdDevices?.find((x) => x.id === row.original.id) !==
            undefined
          }
        ></Checkbox>
      ),
      width: 50,
    },
    {
      Header: 'DisplayName',
      accessor: 'displayName',
      disableSortBy: true,
      Cell: ({ value, row }) => (
        <a
          href={`https://endpoint.microsoft.com/#view/Microsoft_AAD_Devices/DeviceDetailsBlade/objectId/${row.original.id}`}
          target="_blank"
        >
          {value}
        </a>
      ),
    },
    {
      Header: 'Manage',
      accessor: 'manage',
      disableSortBy: true,
      width: 300,
      Cell: ({
        value,
        row,
      }: {
        value: any;
        row: RowReactTable<AzureAdDevice>;
      }) => <ManagedDeviceButton azureAdDevice={row.original} />,
    },
  ] as Column<AzureAdDevice>[];

  return (
    <Table<AzureAdDevice>
      rowSelectionCallback={setSelectedAzureAdDevices}
      rowSelectedKeys={
        selectedAzureAdDevices?.map((device) => device.id!) || undefined
      }
      columns={columns}
      urlPrefix={urlPrefix}
      rowLink={undefined}
      selectType="Multi"
      searchPlaceHolder={'Search in DisplayName (startsWith)'}
    />
  );
}

function ManagedDeviceButton({
  azureAdDevice,
}: {
  azureAdDevice: AzureAdDevice;
}) {
  const { data, loading, error, status } = useData<ManagedDevice | undefined>(
    `/api/operations/manageddevices/by-azureaddevice/${azureAdDevice.deviceId}`,
    undefined,
  );

  return (
    <div>
      {loading ? (
        <Skeleton.Button active={true} size={'small'} block={false} />
      ) : data !== undefined && data.id ? (
        <div>
          <a
            href={`https://endpoint.microsoft.com/#view/Microsoft_Intune_Devices/DeviceSettingsMenuBlade/~/overview/mdmDeviceId/${data.id}`}
            target="_blank"
          >
            Manage
          </a>
          {data.userId && (
            <span>
              {' '}
              (
              <a
                href={`https://endpoint.microsoft.com/#view/Microsoft_AAD_UsersAndTenants/UserProfileMenuBlade/~/overview/userId/${data.userId}`}
                target="_blank"
              >
                {data.userDisplayName}
              </a>
              )
            </span>
          )}
        </div>
      ) : (
        'unmanaged'
      )}
    </div>
  );
}

function MultiSelectHandler({
  setSelectedAzureAdDevices,
  selectedAzureAdDevices,
}: {
  setSelectedAzureAdDevices: React.Dispatch<
    React.SetStateAction<AzureAdDevice[] | undefined>
  >;
  selectedAzureAdDevices: AzureAdDevice[];
}) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {selectedAzureAdDevices.length > 0 && (
        <a onClick={showModal}>
          <Badge
            style={{ marginLeft: -4 }}
            color="blue"
            count={selectedAzureAdDevices.length}
          ></Badge>
        </a>
      )}
      <Modal
        title="Selected devices"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width="60%"
      >
        <SelectedDevicesHandler
          selectedAzureAdDevices={selectedAzureAdDevices}
          setSelectedAzureAdDevices={setSelectedAzureAdDevices}
        />
      </Modal>
    </div>
  );
}

export function SelectedDevicesHandler({
  setSelectedAzureAdDevices,
  selectedAzureAdDevices,
}: {
  setSelectedAzureAdDevices: React.Dispatch<
    React.SetStateAction<AzureAdDevice[] | undefined>
  >;
  selectedAzureAdDevices: AzureAdDevice[];
}) {
  return (
    <>
      {selectedAzureAdDevices.map((selectedAzureAdDevice, i) => (
        <Row
          style={{
            backgroundColor: i % 2 !== 0 ? '#efefef' : undefined,
            marginBottom: 1,
            padding: 2,
          }}
        >
          <Col span={24}>
            <Checkbox
              onChange={(e: CheckboxChangeEvent) =>
                setSelectedAzureAdDevices(
                  e.target.checked
                    ? [...selectedAzureAdDevices, selectedAzureAdDevice]
                    : selectedAzureAdDevices.filter(
                        (x) => x.id !== selectedAzureAdDevice.id,
                      ),
                )
              }
              checked={true}
            ></Checkbox>{' '}
            {selectedAzureAdDevice.displayName}
          </Col>
        </Row>
      ))}
      {selectedAzureAdDevices.length == 0 && (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
}
