import * as React from "react"
import { useNavigate, useParams } from "react-router-dom"
import { PageHeader } from '@ant-design/pro-layout';
import { PartitionOutlined } from "@ant-design/icons"
import styled from "styled-components"

import { PredefinedFilters } from "../generic/table/predefined-filters"
import { useState } from "react"
import { MultiTargetListDefault } from "./list_default"
import { MultiTargetListByRequest } from "./list_byRequest"

export function MultiTargetListView() {
  const { clientPlatform } = useParams()

  const navigate = useNavigate()

  const [urlPredefinedFilter, setUrlPredefinedFilter] = useState<
    string | undefined
  >()

  return (
    <div>
      <Header
        title={
          <div>
            <span>
              <PartitionOutlined /> Multi Target
            </span>
          </div>
        }
        extra={[
          <PredefinedFilters
            filters={[
              { label: "All applications", key: "allPackages" },
              //{ label: "Active Packages", key: "activePackages" },
              { label: "My Applications", key: "myPackages" },
              { label: "My Requests", key: "myRequests" },
              { label: "All Requests", key: "allRequests" },
            ]}
            urlSetter={setUrlPredefinedFilter}
          />,
        ]}
      />

      {(urlPredefinedFilter == "allPackages" ||
        urlPredefinedFilter == "myPackages") && (
        <MultiTargetListDefault
          urlPredefinedFilter={urlPredefinedFilter || ""}
        />
      )}
      {(urlPredefinedFilter == "myRequests" ||
        urlPredefinedFilter == "allRequests") && (
        <MultiTargetListByRequest
          urlPredefinedFilter={urlPredefinedFilter || ""}
        />
      )}
    </div>
  )
}

const Header = styled(PageHeader)`
  background: #fff;
`
