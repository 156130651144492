import { MenuProps } from 'antd';
import React, { useState } from 'react';
export type MenuItem = Required<MenuProps>['items'][number];
export function getMenuItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
  onClick?: () => void,
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    onClick,
  } as MenuItem;
}
