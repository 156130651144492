import * as React from "react"
import { Alert, Col, Collapse, Form, Input, Row, Spin, Tabs } from "antd"
import { defaultMultiTargetRequest, MultiTargetRequest } from "../ts-models"
import { GetField } from "../generic/field"
import { FormContainer } from "../generic/form-container"
import { useActiveSections } from "../generic/workflow"
import { useNavigate, useParams } from "react-router"
import { FieldProps, useFormikContext } from "formik"
import { useWorkflowDetailView } from "../generic/use-workflow-detailview"
import Icon, {
  InfoCircleOutlined,
  LaptopOutlined,
  PartitionOutlined,
  UserOutlined,
  WarningOutlined,
} from "@ant-design/icons"
import { OsdTable } from "./OsdTable"
import { Field as FormikField } from "formik"

export const Field = GetField<MultiTargetRequest>()

export function MultiTargetRequestDetailView() {
  const navigate = useNavigate()
  const { parentId } = useParams()

  return (
    <FormContainer<MultiTargetRequest>
      baseUrl="/api/multi-target-request"
      defaultValue={defaultMultiTargetRequest}
      navigateBackAction={() => {
        navigate(`/multi-target/${parentId}`)
      }}
      navigateAfterDeleteAction={() => {
        navigate(`/multi-target/${parentId}`)
      }}
      rootTitle={
        <>
          <PartitionOutlined /> Multi Target
        </>
      }
    >
      <CollapseContainer />
    </FormContainer>
  )
}

function CollapseContainer(this: React.Component) {
  const activeSections = useActiveSections()
  const { values } = useFormikContext<MultiTargetRequest>()
  const {
    value: { workflowSchema: data, entity, currentStateUserHasPermission },
  } = useWorkflowDetailView<MultiTargetRequest>()
  const { workflowInstance } = entity

  if (!data) {
    return <Spin spinning={true}></Spin>
  }
  return (
    <div>
      {/* <pre>{JSON.stringify(entity, null, 5)}</pre> */}
      <div>
        {/* ({$WorkflowStatus,2} ) AND {$WorkflowToken} AND ('{RemovalType}' = 'RemovalAndDecomission')  */}
        {currentStateUserHasPermission &&
          workflowInstance.currentStateId == 200 &&
          entity.removalType == "RemovalDecommision" && (
            <Alert
              style={{ marginBottom: 10 }}
              message="Hold on!"
              description={
                <div>
                  {" "}
                  Please follow manual tasks below before proceeding with
                  workflow:
                  <br /> - Set corresponding SilverMonkey package or application
                  workflows to EOL
                  <br /> - Move corresponding ConfigMgr package to "PKG Factory
                  Prod - EOL" folder or change application category to "SIM App
                  State [] EOL"
                </div>
              }
              type="warning"
              showIcon={true}
            />
          )}
        {/* $WorkflowStatus,6} AND  {$WorkflowToken} AND ('{RemovalType}' = 'Decomission')  */}
        {currentStateUserHasPermission &&
          workflowInstance.currentStateId == 700 &&
          entity.removalType == "Decommision" && (
            <Alert
              style={{ marginBottom: 10 }}
              message="Hold on!"
              description={
                <div>
                  Please follow manual tasks below before proceeding with
                  workflow:
                  <br />- Set corresponding SilverMonkey package or application
                  workflows to EOL
                  <br /> - Move corresponding ConfigMgr package to "PKG Factory
                  Prod - EOL" folder or change application category to "SIM App
                  State [] EOL"
                  <br /> - Remove from OSD
                  <br /> - Remove from Distribution Points
                  <br /> - Delete deployment collections
                </div>
              }
              type="warning"
              showIcon={true}
            />
          )}
        {/* "{$WorkflowStatus,6} AND  {$WorkflowToken} AND ('{RemovalType}' = 'RemovalAndDecomission') " */}
        {currentStateUserHasPermission &&
          workflowInstance.currentStateId == 700 &&
          entity.removalType == "RemovalDecommision" && (
            <Alert
              style={{ marginBottom: 10 }}
              message="Hold on!"
              description={
                <div>
                  Please follow manual tasks below before proceeding with
                  workflow:
                  <br /> - Remove from OSD
                  <br /> - Remove from Distribution Points
                  <br /> - Delete deployment collections
                </div>
              }
              type="warning"
              showIcon={true}
            />
          )}
      </div>
      <Collapse
        defaultActiveKey={activeSections}
        key={JSON.stringify(activeSections)}
      >
        <Collapse.Panel
          header={
            <span>
              General info for{" "}
              {entity.multiTarget.genericIntegration.type ==
              "Win10ClassicIntegration" ? (
                <span>
                  <a
                    href={`/integrations/win10-classic/${entity.multiTarget.genericIntegration.id}`}
                    target="_blank" rel="noreferrer"
                  >
                    {entity.multiTarget.genericIntegration.displayName}
                  </a>
                </span>
              ) : (
                <span>
                  <a
                    href={`/integrations/win10-app/${entity.multiTarget.genericIntegration.id}`}
                    target="_blank" rel="noreferrer"
                  >
                    {entity.multiTarget.genericIntegration.displayName}
                  </a>
                </span>
              )}
            </span>
          }
          key="GeneralInfo"
        >
          <div>
            <div className="dyn-grid">
              {values.isDeployRequest == false && (
                <Row gutter={[24, 16]}>
                  <Col lg={24} xl={20}>
                    <Field name="removalType" />
                  </Col>
                </Row>
              )}

              <Form.Item label="Multi Target Request Id">
                <Input value={entity.requestNumber} disabled={true} />
              </Form.Item>

              <Form.Item label="Ticket ID">
                <Input value={entity.ticketId || ""} disabled={true} />
              </Form.Item>
              <Form.Item label="Manufacturer">
                <Input
                  value={
                    entity.multiTarget?.genericIntegration?.manufacturer || ""
                  }
                  disabled={true}
                />
              </Form.Item>
              <Form.Item label="Product name">
                <Input
                  value={
                    entity.multiTarget?.genericIntegration?.productname || ""
                  }
                  disabled={true}
                />
              </Form.Item>
              <Form.Item label="Product version">
                <Input
                  value={
                    entity.multiTarget?.genericIntegration?.productVersion || ""
                  }
                  disabled={true}
                />
              </Form.Item>
              <Form.Item label="Package Id">
                <Input
                  value={
                    entity.multiTarget?.genericIntegration?.requestID || ""
                  }
                  disabled={true}
                />
              </Form.Item>
              <Form.Item label="Language">
                <Input
                  value={
                    entity.multiTarget?.genericIntegration?.language?.isoCode ||
                    ""
                  }
                  disabled={true}
                />
              </Form.Item>
              <Form.Item label="Platform">
                <Input
                  value={entity.multiTarget?.genericIntegration?.type || ""}
                  disabled={true}
                />
              </Form.Item>
            </div>
            <Row gutter={[24, 16]}>
              <Col lg={24} xl={20}>
                <Field name="comment" />
              </Col>
            </Row>
          </div>
        </Collapse.Panel>

        <Collapse.Panel
          header="Pilot Deployment"
          key="PilotDeployment"
          className="panel-grid"
        >
          {currentStateUserHasPermission && (
            <OperationsTabs entity={entity} mode="pilot" />
          )}
        </Collapse.Panel>
        <Collapse.Panel header="Rollout" key="Rollout">
          {currentStateUserHasPermission && (
            <OperationsTabs entity={entity} mode="rollout" />
          )}
        </Collapse.Panel>

        <Collapse.Panel
          header="Operating System Deployment Windows 10"
          key="OsdWin10"
        >
          <OsdTable
            pkgID={entity.sccmObjectId || ""}
            isDeployRequest={entity.isDeployRequest}
            pkgType={entity.sccmObjectIsApplication ? "other" : "PkgJob"}
            sccmPackagePrograms={
              entity.sccmPackagePrograms
                ? entity.sccmPackagePrograms.map((x) => x.name || "")
                : null
            }
          />
        </Collapse.Panel>
      </Collapse>
      {/* <FormikField>
        {({ field, form }: FieldProps<any>) => (
          <pre>{JSON.stringify({ field }, null, 2)}</pre>
        )}
      </FormikField> */}
    </div>
  )
}

function OperationsTabs({
  entity,
  mode,
}: {
  entity: MultiTargetRequest
  mode: "pilot" | "rollout"
}) {
  const TabPane = Tabs.TabPane
  let WorkflowURLSuffix =
    mode == "pilot"
      ? getOPSPilotURLWorkflowSuffix(entity.workflowInstance.currentStateId)
      : getOPSRolloutURLWorkflowSuffix(entity.workflowInstance.currentStateId)
  return (
    <Tabs defaultActiveKey="1">
      <TabPane
        tab={
          <span>
            <InfoCircleOutlined />
            Info
          </span>
        }
        key="1"
      >
        <WarningOutlined />
        <span> Select user or device tab to define your deployment</span>
      </TabPane>
      <TabPane
        tab={
          <span>
            <UserOutlined />
            Deploy to user
          </span>
        }
        key="2"
      >
        {!entity.sccmObjectIsApplication ? (
          <iframe
            src={
              entity.operationsUrl +
              "/packages/" +
              entity.sccmObjectId +
              "/mass-user-deployments?category=" +
              mode +
              WorkflowURLSuffix
            }
            style={{ border: "0px", height: "700px", width: "100%" }}
          />
        ) : (
          <iframe
            src={
              entity.operationsUrl +
              "/applications/" +
              entity.sccmObjectId +
              "/mass-user-deployments?category=" +
              mode +
              WorkflowURLSuffix
            }
            style={{ border: "0px", height: "700px", width: "100%" }}
          />
        )}
      </TabPane>
      <TabPane
        tab={
          <span>
            <LaptopOutlined />
            Deploy to devices
          </span>
        }
        key="3"
      >
        {!entity.sccmObjectIsApplication ? (
          <iframe
            src={
              entity.operationsUrl +
              "/packages/" +
              entity.sccmObjectId +
              "/mass-device-deployments?category=" +
              mode +
              WorkflowURLSuffix
            }
            style={{ border: "0px", height: "700px", width: "100%" }}
          />
        ) : (
          <iframe
            src={
              entity.operationsUrl +
              "/applications/" +
              entity.sccmObjectId +
              "/mass-device-deployments?category=" +
              mode +
              WorkflowURLSuffix
            }
            style={{ border: "0px", height: "700px", width: "100%" }}
          />
        )}
      </TabPane>
    </Tabs>
  )
}

function getOPSPilotURLWorkflowSuffix(openstepId: number) {
  // v6 workflow states
  // ---------------------------------
  // 1 = A1 Request
  // 2 = A2 Approval Pilot
  // 3 = A3 Control Pilot and Rollout
  // 4 = A4 Approval Rollout
  // 5 = A5 Rollout Running
  // 6 = A7 Close
  // 9 = A6 Obsolete

  // public enum MultiTargetRequestWorkflowStates
  // {
  //     A0 = 0,
  //     A1 = 100,
  //     A2 = 200,
  //     A3 = 300,
  //     A4 = 400,
  //     A5 = 500,
  //     A6 = 600,
  //     A7 = 700,
  // }

  // if (this.setWorkflowRight() === false) {
  //   return [
  //     "&disable-approve&disable-add-mass-deployment&mass-deployments-scope&disable-delete-mass-deployment"
  //   ];
  // }

  if (openstepId == 100) {
    return ["&disable-approve&mass-deployments-scope"]
  }

  if (openstepId == 200) {
    return ["&disable-add-mass-deployment&mass-deployments-scope"]
  }

  if (openstepId == 300) {
    return [
      "&disable-approve&disable-add-mass-deployment&mass-deployments-scope",
    ]
  }

  if (openstepId == 400) {
    return ["&disable-add-mass-deployment&mass-deployments-scope"]
  }

  if (openstepId >= 500) {
    return [
      "&disable-approve&disable-add-mass-deployment&mass-deployments-scope",
    ]
  }
}

function getOPSRolloutURLWorkflowSuffix(openstepId: number) {
  // v6 workflow states
  // ---------------------------------
  // 1 = A1 Request
  // 2 = A2 Approval Pilot
  // 3 = A3 Control Pilot and Rollout
  // 4 = A4 Approval Rollout
  // 5 = A5 Rollout Running
  // 6 = A7 Close
  // 9 = A6 Obsolete

  // public enum MultiTargetRequestWorkflowStates
  // {
  //     A0 = 0,
  //     A1 = 100,
  //     A2 = 200,
  //     A3 = 300,
  //     A4 = 400,
  //     A5 = 500,
  //     A6 = 600,
  //     A7 = 700,
  // }

  // if (this.setWorkflowRight() === false) {
  //   return [
  //     "&disable-approve&disable-add-mass-deployment&mass-deployments-scope&disable-delete-mass-deployment"
  //   ];
  // }

  if (openstepId <= 300) {
    return ["&disable-approve&mass-deployments-scope"]
  }

  if (openstepId == 400) {
    return ["&disable-add-mass-deployment&mass-deployments-scope"]
  }

  if (openstepId == 500) {
    return [
      "&disable-approve&disable-add-mass-deployment&mass-deployments-scope&disable-delete-mass-deployment",
    ]
  }

  if (openstepId >= 600) {
    return [
      "&disable-approve&disable-add-mass-deployment&mass-deployments-scope",
    ]
  }
}
