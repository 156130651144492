import { RunActionButton, WorkflowInstance } from './ts-models';

export interface ModelProperty {
  label: string | null;
  type: string | null;
  dataSource: IDataSource;
}
export interface Model {
  properties: { [key: string]: ModelProperty };
}

export const defaultModel: Model = {
  properties: {},
};
export interface Profile {
  displayName: string | null;
  id: string | null;
  email: string | null;
  identityName: string | null;
  isAuthenticated: boolean;
  userId: string | null;
}

export interface UserClaim {
  value: string;
  type: string;
}

export const defaultUserClaim: UserClaim = {
  value: '',
  type: '',
};

export interface IDataSource {
  type: 'Api' | 'Enum' | 'Config';
}
export interface ApiDataSource extends IDataSource {
  apiUrl: string | null;
  type: 'Api';
  backingField: string | null;
}

export interface ConfigDataSource extends IDataSource {
  values: { key: string; label: string }[];
  type: 'Config';
}
export interface EnumDataSource extends IDataSource {
  values: { key: string; label: string }[];
  type: 'Enum';
}

export interface QueryResult<T> {
  count: number;
  value: T[];
  skipToken?: string | undefined;
}

export const defaultQueryResult: QueryResult<any> = {
  count: 0,
  value: [],
  skipToken: '',
};

export interface State {
  id: number;
  displayName: string | null;
  writePolicies: string[];
  deleteEnabled: boolean | null;
  editableFields: string[] | null[];
  activeSections: string[] | null[];
  hiddenFields: string | null[];
  mandatoryFields: string[] | null[];
  runActionButtons: RunActionButton[] | null;
}

export const defaultState: State = {
  id: 0,
  displayName: '',
  writePolicies: [],
  deleteEnabled: null,
  editableFields: [],
  activeSections: [],
  hiddenFields: [],
  mandatoryFields: [],
  runActionButtons: [],
};

export interface MsGraphDirectoryObject {
  id: string | null;
  displayName: string | null;
}

// export interface StatusQueryUriResult {
//   customStatus?: StatusQueryCustomStatus | undefined;
//   runtimeStatus: 'Completed' | 'Running' | 'Failed' | 'Pending' | undefined;
//   output: string | undefined;
// }

// export interface StatusQueryCustomStatus {
//   LogEntries: string[];
//   CreatedResource: string | undefined;
// }
