import * as React from 'react';
import { Col, Collapse, Row, Spin } from 'antd';
import {
  defaultWin10ClassicPlatformApproval,
  IWorkflow,
  Win10ClassicPlatformApproval,
} from '../../ts-models';
import { GetField } from '../../generic/field';
import { FormContainer } from '../../generic/form-container';
import { useActiveSections } from '../../generic/workflow';
import { useNavigate, useParams } from 'react-router';
import { useFormikContext } from 'formik';
import { useWorkflowDetailView } from '../../generic/use-workflow-detailview';
import { WindowsOutlined } from '@ant-design/icons';

export const Field = GetField<Win10ClassicPlatformApproval>();

export function Win10ClassicPlatformApprovalDetailView() {
  const navigate = useNavigate();
  const { integrationId } = useParams();
  return (
    <FormContainer<Win10ClassicPlatformApproval>
      baseUrl="/api/integrations/win10-classic-platformapproval"
      defaultValue={defaultWin10ClassicPlatformApproval}
      navigateBackAction={() => {
        navigate(`/integrations/win10-classic/${integrationId}`);
      }}
      rootTitle={
        <>
          <WindowsOutlined /> Windows Desktop Application
        </>
      }
    >
      <CollapseContainer />
    </FormContainer>
  );
}

function CollapseContainer(this: React.Component) {
  const activeSections = useActiveSections();
  const { values } = useFormikContext<Win10ClassicPlatformApproval>();
  const {
    value: { workflowSchema: data, entity },
  } = useWorkflowDetailView<IWorkflow>();
  const { workflowInstance } = entity;
  const { integrationId } = useParams();

  if (!activeSections) {
    return <Spin spinning={true}></Spin>;
  }
  return (
    <div>
      <Collapse
        defaultActiveKey={activeSections}
        key={JSON.stringify(activeSections)}
      >
        <Collapse.Panel
          header="Platform Approval"
          key="PlatformApproval"
          className="panel-grid"
        >
          <div>
            <Field name="approvalPlatform" referenceId={integrationId} />
            <Field name="ticketId" />
          </div>
          <div>
            <Field name="ticketRequestReference" />
            <Field name="ticketResponsible" />
          </div>
        </Collapse.Panel>
        <Collapse.Panel header="Quality Assurance" key="QualityAssurance">
          <div className="dyn-grid">
            <div>
              <Field name="nftSuccessful" />
            </div>
            <div>
              <Field name="nftDate" />
              <Field name="nftTester" />
            </div>
          </div>
          {workflowInstance.currentStateId > 0 && (
            <Row gutter={[24, 16]}>
              <Col lg={24}>
                <strong>
                  Please do the following non-function tests for quality
                  assurance:
                </strong>
              </Col>
              <Col md={24} lg={12}>
                Installation:
                <ul>
                  <li>No messages at installation?</li>
                  <li>Is the test scenario from installation guide ok?</li>
                  <li>
                    Are all the adjustments according to installation guide?
                  </li>
                  <li>
                    All the shortcuts and program folder according to
                    installation guide?
                  </li>
                  <li>Are the application starts / ends correct?</li>
                </ul>
              </Col>
              <Col md={24} lg={12}>
                Uninstallation:
                <ul>
                  <li>No messages at uninstallation?</li>
                  <li>
                    Are the adjustments according to installation guide removed?
                  </li>
                  <li>
                    Are all the shortcuts and program folder correctly removed?
                  </li>
                  <li>No unexpected reboot after uninstallation?</li>
                </ul>
              </Col>
            </Row>
          )}
          <Row gutter={[24, 16]}>
            <Col lg={24}>
              <Field name="nftComment" />
            </Col>
          </Row>
        </Collapse.Panel>
        <Collapse.Panel header="Approval TS" key="ApprovalTs">
          <div className="dyn-grid">
            <div>
              <Field name="approvalDeadlineTs" />
            </div>
            <div>
              <Field name="ftTestSystemsTs" />
            </div>
            <div>
              <Field name="nftTsSuccessful" />
            </div>
          </div>
          {workflowInstance.currentStateId > 0 && (
            <Row gutter={[24, 16]}>
              <Col lg={24}>
                <strong>
                  Please do the following non-function tests for terminal
                  server:
                </strong>
              </Col>
              <Col md={24} lg={12}>
                <ol start={1}>
                  <li>
                    Connect to Terminal Server from the email you received.
                  </li>
                  <li>
                    Logon with your user credentials or others if necessary.
                  </li>
                  <li>
                    Install the software using the Software Center and wait till
                    the installation of the software is done.
                  </li>
                  <li>
                    Start the application and test the functionality of the
                    software. Does it work and is it configured like you
                    described in the Package Request?
                  </li>
                </ol>
              </Col>
              <Col md={24} lg={12}>
                <ol start={5}>
                  <li>
                    If needed, make the modification on the AppSence environment
                    and document your modification here:{' '}
                    <a
                      href="onenote:https://teamnet.grouphc.net/git/GIP/RIS/EMEA/AH%20Documents/OneNote/AH%20XenApp%20Documentation/Document%20Template's.one#%5bApp%20Template%5d&section-id={7157BD8A-F251-49A9-BF8B-01A13F9E91A1}&page-id={A6E882D4-F232-7E41-960E-BD2867C10E98}&end"
                      target="_blank"
                      rel="noreferrer"
                    >
                      AppSence documentation
                    </a>
                  </li>
                  <li>
                    Test the functionality of the software again till approval
                    is finally successful (if possible).
                  </li>
                  <li>
                    If any error occurs and the application does not work like
                    you expected, document the issues with screenshots in the
                    section below, this means:
                    <ul>
                      <li>
                        Create screenshot(s) and/or description of every
                        configuration that has to be changed and of every error
                        that occurs.
                      </li>
                      <li>Make comments/notes describing the screenshots</li>
                    </ul>
                  </li>
                </ol>
              </Col>
            </Row>
          )}
          <Row gutter={[24, 16]}>
            <Col lg={24}>
              <Field name="qaTsComment" />
            </Col>
          </Row>
        </Collapse.Panel>
        <Collapse.Panel header="Approval AO" key="ApprovalAo">
          <div className="dyn-grid">
            <div>
              <Field name="approvalDeadline" />
            </div>
            <div>
              <Field name="ftTestSystemNeeded" />
            </div>
            <div>
              <Field name="ftTestSystems" />
            </div>
            <div>
              <Field name="approvalPassed" />
            </div>
          </div>
          {workflowInstance.currentStateId > 0 && (
            <Row gutter={[24, 16]}>
              <Col lg={24}>
                <strong>
                  The application is assigned to Application Owner and Technical
                  Contact by default. Please follow the instructions below:
                </strong>
              </Col>
              <Col md={24} lg={12}>
                <ol start={1}>
                  <li>
                    If you would like to run the test on your or another client
                    system, you can continue with Step 2.
                    <ul>
                      <li>
                        <strong>If you need an approval machine</strong>, please
                        check the “Approval machine needed:” check box and move
                        the workflow back to “Approval machine needed”. As soon
                        the approval machine is prepared, you will receive an
                        email with the name of the Approval machine (virtual
                        workstation) assigned to you. Please continue than with
                        Step 2.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Log in with your user credentials. Don’t use any admin
                    login.
                  </li>
                  <li>
                    Install the software using the Software Center (or Company
                    Portal) and wait till the installation is done. For more
                    information, please see on DWPHub{' '}
                    <a
                      target="_blank"
                      href="https://hcgroupnet.sharepoint.com/sites/GLB_dwphub/SitePages/App-Pages/access-desktop-applications.aspx?originalPath=aHR0cHM6Ly9oY2dyb3VwbmV0LnNoYXJlcG9pbnQuY29tLzp1Oi9zL0dMQl9kd3BodWIvRWJUZ1ZuejkybDFBbTI4c2dUclI0S0VCS2lEdE01eHYwZkVTeVdueVZ1MFJyQT9ydGltZT05T2hyU1ozOTJFZw"
                    >
                      How to Install Desktop Applications
                    </a>
                    .
                  </li>
                  <li>
                    Start the application and test the functionality of the
                    software. (Try to open documents, save documents, print,
                    connect to server (if required), browse, etc.).
                  </li>
                </ol>
              </Col>
              <Col md={24} lg={12}>
                <ol start={5}>
                  <li>
                    Uninstall the application by clicking the Uninstall button
                    in the Software Center (or Company Portal).
                  </li>
                  <li>
                    If any error occurs or the application does not work as
                    expected, document the issues with screenshots in the
                    section “Approval AO Comment”.
                    <ul>
                      <li>
                        Create screenshot(s) and/or description of every
                        configuration that must be changed and of every error
                        that occurs.
                      </li>
                      <li>Make comments/notes describing the screenshots.</li>
                    </ul>
                  </li>
                  <li>
                    Once all tests are done (whether it is successful or not)
                    move the workflow to the next step depending on the result
                    “A6.3 - Approval AO – OK” or “A6.4 - Approval AO - NOT OK”.
                  </li>
                  <li>If needed, we will contact you again.</li>
                </ol>
              </Col>
            </Row>
          )}
          <Row gutter={[24, 16]}>
            <Col lg={24}>
              <Field name="qaAoComment" />
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}
