import * as React from "react"
import { useNavigate, useParams } from "react-router-dom"
import { PageHeader } from '@ant-design/pro-layout';
import { Button } from "antd";
import {
  AndroidOutlined,
  AppleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons"
import styled from "styled-components"
import { v4 as guid } from "uuid"

import { MobileAppIntegrationListDto } from "../../ts-models"

import { DateTime } from "luxon"
import { Column } from "react-table"
import { Table } from "../../generic/table/table"
import { PredefinedFilters } from "../../generic/table/predefined-filters"
import { useState } from "react"

export function MobileAppIntegrationListView() {
  const { clientPlatform } = useParams()

  const navigate = useNavigate()
  const urlPrefix = `/api/integrations/mobile-app/list?clientPlatform=${clientPlatform}`

  const [urlPredefinedFilter, setUrlPredefinedFilter] = useState<
    string | undefined
  >()

  const columns = [
    {
      Header: "Manufacturer",
      accessor: "manufacturer",
      Cell: ({ value }) => value || "?",
    },
    {
      Header: "Product Name",
      accessor: "productname",
      Cell: ({ value }) => value || "?",
    },
    {
      Header: "Product Version",
      accessor: "productVersion",
      Cell: ({ value }) => value || "?",
    },
    {
      Header: "Request Id",
      accessor: "requestID",
    },
    {
      Header: "Status",
      accessor: "workflowInstance.currentStateName",
      disableSortBy: true,
    },
    {
      Header: "Application Type",
      accessor: "applicationTypeMDM",
    },
    {
      Header: "Application Layer",
      accessor: "applicationLayer",
    },
    {
      Header: "Licensing Model",
      accessor: "licensingModel",
    },
    {
      Header: "Approval Deadline",
      accessor: "approvalDeadline",
      Cell: ({ value }) =>
        value &&
        DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY),
    },
    {
      Header: "App Owner",
      accessor: "appOwner.displayName",
      disableSortBy: true,
    },
    {
      Header: "App Importance",
      accessor: "appImportance",
    },
    {
      Header: "License Req.",
      accessor: "licenseRequired",
    },
    {
      Header: "Updated At",
      accessor: "updatedAt",
      Cell: ({ value }) =>
        DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT),
    },
    {
      Header: "Created by",
      accessor: "createdBy",
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      Cell: ({ value }) =>
        DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY),
    },
  ] as Column<MobileAppIntegrationListDto>[]

  return (
    <div>
      <Header
        title={
          <div>
            {clientPlatform == "android" ? (
              <span>
                <AndroidOutlined /> Android Apps
              </span>
            ) : (
              <span>
                <AppleOutlined /> iOS Apps
              </span>
            )}
            <Button
              style={{ marginLeft: 20 }}
              icon={<PlusCircleOutlined />}
              type="primary"
              onClick={() => {
                navigate(
                  `/integrations/mobile-app/${clientPlatform}/${guid()}?create=true`,
                )
              }}
            >
              New Request
            </Button>
          </div>
        }
        extra={[
          <PredefinedFilters
            filters={[
              { label: "In Progress", key: "inProgress" },
              //{ label: "Active Packages", key: "activePackages" },
              { label: "Operating", key: "inOperation" },
              { label: "My Packages (AO)", key: "myPackages" },
              { label: "New Requests", key: "newRequests" },
              { label: "All packages", key: "allPackages" },
            ]}
            urlSetter={setUrlPredefinedFilter}
          />,
        ]}
      />

      <Table<MobileAppIntegrationListDto>
        columns={columns}
        urlPrefix={
          urlPrefix +
          `&urlPredefinedFilter=${
            urlPredefinedFilter ? urlPredefinedFilter : ""
          }`
        }
        rowLink={(row) => `./${row.id}`}
        // orderBy="manufacturer"
      />
    </div>
  )
}

const Header = styled(PageHeader)`
  background: #fff;
`
