import * as React from 'react';
import {
  Collapse,
  Row,
  Col,
  Spin,
  Button,
  Modal,
  Alert,
  Typography,
} from 'antd';
import {
  AndroidOutlined,
  AppleOutlined,
  CloudUploadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
  defaultMobileAppIntegration,
  IWorkflow,
  MobileAppIntegration,
} from '../../ts-models';
import { GetField } from '../../generic/field';
import { FormContainer } from '../../generic/form-container';
import { useActiveSections } from '../../generic/workflow';
import { v4 as guid } from 'uuid';
import { Field as FormikField, FieldProps, useFormikContext } from 'formik';
import { useState } from 'react';
import storeScreenshotIos from './img/store-screenshot-ios.png';
import storeScreenshotAndroid from './img/store-screenshot-android.png';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useNavigate, useParams } from 'react-router';
import { useWorkflowDetailView } from '../../generic/use-workflow-detailview';
import { IntuneMobileAppNotes } from '../../generic/intuneMobileAppNotes';
import { androidUrl, iosAppUrl } from '..';

export const Field = GetField<MobileAppIntegration>();

function StoreInstructionsModalAndroid() {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Button type="default" onClick={() => setVisible(true)}>
        Help
      </Button>
      <Modal
        title="Import info from store"
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={806}
      >
        <p>
          Welcome to the request of new Android Application. Please initiate
          provisioning by choosing "Open Google Play Store" button below and
          then click on "Ok". Search for the application you would like to
          request in store. Copy and paste the URL of the application, name and
          manufacturer (see yellow marker the example below).
        </p>
        <p>
          <img src={storeScreenshotAndroid} style={{ width: '100%' }} />
        </p>
        <a
          href="https://play.google.com/store/apps"
          target="_blank"
          rel="noreferrer"
        >
          Open Store
        </a>
      </Modal>
    </>
  );
}

function StoreInstructionsModalIos() {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Button type="default" onClick={() => setVisible(true)}>
        Help
      </Button>
      <Modal
        title="Import info from store"
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={806}
      >
        <p>
          Welcome to the request of new iOS Application. Please initiate
          provisioning by choosing "Open App Store" button below and then click
          on "Ok". Search for the application you would like to request using
          "fnd.io" portal and open it using "AppStore" button. Copy and paste
          the URL of the application, name and manufacturer (see yellow marker
          the example below).
        </p>

        <p>
          <img src={storeScreenshotIos} style={{ width: '100%' }} />
        </p>
        <a href="https://fnd.io/" target="_blank" rel="noreferrer">
          Open Store
        </a>
      </Modal>
    </>
  );
}

export function MobileAppIntegrationDetailView() {
  const { id, clientPlatform } = useParams();
  const navigate = useNavigate();
  return (
    <FormContainer<MobileAppIntegration>
      key={id}
      baseUrl="/api/integrations/mobile-app"
      defaultValue={defaultMobileAppIntegration}
      rootTitle={
        clientPlatform == 'android' ? (
          <span>
            <AndroidOutlined /> Android Apps
          </span>
        ) : (
          <span>
            <AppleOutlined /> iOS Apps
          </span>
        )
      }
      navigateBackAction={() => {
        clientPlatform == 'android'
          ? navigate(androidUrl)
          : navigate(iosAppUrl);
      }}
      navigateAfterDeleteAction={() => {
        clientPlatform == 'android'
          ? navigate(androidUrl)
          : navigate(iosAppUrl);
      }}
    >
      <CollapseContainer></CollapseContainer>
    </FormContainer>
  );
}

function CollapseContainer(this: React.Component) {
  const activeSections = useActiveSections();
  const { values } = useFormikContext<MobileAppIntegration>();
  const {
    value: { workflowSchema: data, entity, transferFolderSharePoint },
  } = useWorkflowDetailView<IWorkflow>();
  const { workflowInstance } = entity;

  if (!activeSections) {
    return <Spin spinning={true}></Spin>;
  }
  return (
    <div>
      <Collapse
        defaultActiveKey={activeSections}
        key={JSON.stringify(activeSections)}
      >
        <Collapse.Panel
          header="Organizational data"
          key="OrgData"
          className="panel-grid"
        >
          <div>
            <Field name="appOwner" />
            <Field name="technicalContact" />
          </div>
          <div>
            <Field name="countryCoordinator" />
            <Field name="requestPriority" />
          </div>
          <div>
            <Field name="testingResponsible" />
          </div>
          <div>
            <Field name="requestID" />
          </div>
        </Collapse.Panel>
        <Collapse.Panel
          header="Application data"
          key="AppData"
          className="panel-grid"
        >
          <div>
            <div style={{ display: 'flexbox' }}>
              <Row gutter={[24, 16]}>
                <Col md={24} lg={18}>
                  <Field name="applicationTypeMDM" />
                </Col>
                <Col md={24} lg={6}>
                  {values.applicationTypeMDM == 'Public' &&
                    values.clientPlatform == 'Android' && (
                      <StoreInstructionsModalAndroid />
                    )}
                  {values.applicationTypeMDM == 'Public' &&
                    values.clientPlatform == 'iOS' && (
                      <StoreInstructionsModalIos />
                    )}
                </Col>
              </Row>
            </div>
          </div>
          {values.applicationTypeMDM != null && (
            <>
              <div>
                <Field name="manufacturer" />
                <Field name="productname" />
              </div>
              <div>
                <Field name="licenseRequired" />
              </div>
              <div>
                <Field name="productVersion" />
                {values.applicationTypeMDM != 'Internal' && (
                  <Field name="appStoreProductLink" />
                )}
              </div>
              {values.applicationTypeMDM == 'Internal' &&
                (workflowInstance.currentStateId == 100 ||
                  workflowInstance.currentStateId == 200 ||
                  workflowInstance.currentStateId == 700) && (
                  <div>
                    <Alert
                      message={
                        <div>
                          <h3>
                            <CloudUploadOutlined /> File upload
                          </h3>
                          {!values.requestID ||
                          !values.manufacturer ||
                          !values.productname ||
                          !values.productVersion ? (
                            <Paragraph>
                              Please fill in the fields "Manufacturer",
                              "Productname", "Product version" and save form to
                              use file upload.
                            </Paragraph>
                          ) : (
                            <div>
                              <Paragraph>
                                If you need to upload files, please use the
                                following link:
                                <br />
                                <a
                                  href={
                                    values.clientPlatform == 'iOS'
                                      ? transferFolderSharePoint.ios ||
                                        undefined
                                      : transferFolderSharePoint.android ||
                                        undefined
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Upload to Sharepoint
                                </a>
                              </Paragraph>
                              <Paragraph>
                                Please create a folder with the name
                                <Paragraph
                                  copyable={{
                                    tooltips: false,
                                    text: values.sourceFilesDirDisplayName!,
                                  }}
                                >
                                  <Typography.Text code={true}>
                                    {workflowInstance.currentStateId == 100
                                      ? `${values.requestID}_${values.manufacturer}_${values.productname}_${values.productVersion}`
                                      : values.sourceFilesDirDisplayName}
                                  </Typography.Text>
                                </Paragraph>
                              </Paragraph>
                            </div>
                          )}
                        </div>
                      }
                      type="info"
                    />
                  </div>
                )}
              {values.applicationTypeMDM == 'Internal' && (
                <div>
                  <Field name="filesUploaded" />
                </div>
              )}
            </>
          )}
        </Collapse.Panel>
        <Collapse.Panel
          header="Application properties"
          key="AppProps"
          className="panel-grid"
        >
          <div>
            <Field name="clientPlatform" />
            <Field name="requestSource" />
          </div>
          <div>
            <Field name="updateCycle" />
            <Field name="qtyInstallations" />
          </div>
          <div>
            <Field name="appImportance" />
            <Field name="applicationLayer" />
          </div>
          <div>
            <Field name="cloudServicesUsed" />
            <Field name="deployedIn" />
          </div>
        </Collapse.Panel>
        <Collapse.Panel header="Use Case" key="UseCase">
          <Row gutter={[24, 16]}>
            <Col lg={24} xl={20}>
              <Field name="useCase" />
            </Col>
            <Col lg={24} xl={4}>
              <div>
                Within this Use Case Description, you can add some text, images
                and other resources to describe the use case of this
                application.
              </div>
            </Col>
          </Row>
        </Collapse.Panel>
        <Collapse.Panel header="Additional information" key="AppInfos">
          <div className="dyn-grid">
            <div>
              <Field name="languageSupported" />
              <Field name="aprxDownloadSize" />
            </div>
            <div>
              <Field name="category" />
              <Field name="businessAppStoreProductLink" />
            </div>
            <div>
              <Field name="osMinimum" />
            </div>
          </div>
          <Row gutter={[24, 16]}>
            <Col lg={24}>
              <Field name="appPermissions" />
            </Col>
          </Row>
          <Row gutter={[24, 16]}>
            <Col lg={24}>
              <Field name="description" />
            </Col>
          </Row>
        </Collapse.Panel>
        <Collapse.Panel
          header="Provisioning (MDM)"
          key="Provisioning"
          className="panel-grid"
        >
          <div>
            <Field name="ticketID" />
            <Field name="ticketResponsible" />
            <Field name="webLink" />
          </div>
          <div>
            <Field name="configurationSettings" />
            <Field name="communicationOptions" />
            <Field name="webSecurity" />
          </div>
          <div>
            <Field name="appTunnelingServer" />
            <Field name="appTunnelingPorts" />
            <Field name="deploymentPushMode" />
          </div>
          <div>
            <Field name="deploymentUserGroup" />
            <Field name="provisionStatusMDM" />
            {values.clientPlatform == 'Android' && <Field name="mdmSystem" />}
            {values.clientPlatform == 'Android' && (
              <Field name="intuneAppRef" />
            )}
            {values.clientPlatform == 'iOS' && (
              <IntuneMobileAppNotes
                manufacturer={values.manufacturer}
                productName={values.productname}
                version={values.productVersion}
                languageCode={values.language?.isoCode || ''}
                architecture={''}
                simRequestId={values.requestID}
              />
            )}
          </div>
        </Collapse.Panel>
        <Collapse.Panel header="Security" key="Security">
          <div className="dyn-grid">
            <div>
              <Field name="securityTrustScore" />
            </div>
            <div>
              <Field name="securityCheckResults" />
            </div>
          </div>
          <Row gutter={[24, 16]}>
            <Col lg={24}>
              <Field name="securityCheckDocument" />
            </Col>
          </Row>
        </Collapse.Panel>
        <Collapse.Panel header="Approval" key="Approval">
          <div className="dyn-grid">
            <div>
              <Field name="approvalDeadline" />
            </div>
            <div>
              <Field name="functionTestPassed" />
            </div>
          </div>
          <Row gutter={[24, 16]}>
            <Col lg={24}>
              <Field name="functionTestDocument" />
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}
