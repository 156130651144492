import { CheckCircleTwoTone, WarningTwoTone } from "@ant-design/icons"
import { Table, Tooltip } from "antd"

import { DateTime } from "luxon"
import * as React from "react"

import { useNavigate } from "react-router"
import { ActionHistory } from "../ts-models"

export function ActionHistoryTable({
  actionHistory,
}: {
  actionHistory: ActionHistory[]
}) {
  const navigate = useNavigate()

  return (
    <Table<ActionHistory>
      bordered={false}
      size="small"
      style={{ flex: 1 }}
      rowKey={(row) => row.id}
      columns={[
        {
          title: "Requester",
          key: "createdBy",
          render: (record: ActionHistory) => <span>{record.createdBy}</span>,
        },

        {
          title: "Timestamp",
          key: "createdAt",
          render: (record: ActionHistory) => (
            <span>
              {DateTime.fromISO(record.createdAt).toLocaleString(
                DateTime.DATETIME_SHORT,
              )}
            </span>
          ),
        },
        {
          title: "Action",
          key: "action",
          render: (record: ActionHistory) => <span>{record.action}</span>,
        },
        {
          title: "Status",
          key: "isSuccessful",
          render: (record: ActionHistory) => (
            <span>
              {record.isSuccessful ? (
                <>
                  <CheckCircleTwoTone twoToneColor="#52c41a" /> Successful
                </>
              ) : (
                <>
                  <WarningTwoTone twoToneColor="#ff4d4f" /> Failed
                </>
              )}
            </span>
          ),
        },

        {
          title: "Message",
          key: "message",
          render: (record: ActionHistory) =>
            record.isSuccessful
              ? record.message
              : "Error! Expand for details...",

          ellipsis: {
            showTitle: false,
          },
        },
      ]}
      expandable={{
        expandedRowRender: (record: ActionHistory) => (
          <code>
            <pre>{record.message}</pre>
          </code>
        ),
        rowExpandable: (record: ActionHistory) => !record.isSuccessful,
      }}
      dataSource={actionHistory ? actionHistory : []}
    />
  )
}
