import * as React from 'react';
import { Route, useNavigate, Routes, Link } from 'react-router-dom';
import { Alert, Layout } from 'antd';
import Icon, { LaptopOutlined, PartitionOutlined } from '@ant-design/icons';
import { constants } from './constants';

import { useParams } from 'react-router';
import { OperationsIntuneListView } from './list';
import { MenuItem, getMenuItem } from '../generic/getMenuItem';
import { useData } from '../generic/glow/actions/use-data';
import { UserScope } from '../ts-models';
import { LoadingIndicator } from '../generic/loadingIndicator';
import { IntuneIcon } from './media/intuneIcon';

export function renderOperationsIntuneMenuItem(): MenuItem {
  return getMenuItem(
    <Link to={paths.main}>{constants.linkName}</Link>,
    paths.main,
    <LaptopOutlined size={150} />,
  );
}

const { paths } = constants;
const { Content } = Layout;

export function OperationsIntuneView() {
  return (
    <Routes>
      <Route
        path={`${paths.main}/:scope`}
        element={
          <Layout>
            <Layout style={{ flex: 1, flexDirection: 'column' }}>
              <Content style={{ flex: 1 }}>
                <OperationsIntuneListView />
              </Content>
            </Layout>
          </Layout>
        }
      ></Route>
      <Route path={`${paths.main}`} element={<RedirectToFirstScope />} />
    </Routes>
  );
}

function RedirectToFirstScope() {
  const navigate = useNavigate();

  const { data: myScopes, isLoading } = useData<UserScope[] | undefined>(
    `/api/operations/my-scopes`,
    undefined,
  );

  const { scope } = useParams();

  React.useEffect(() => {
    if (!scope && myScopes && myScopes.length > 0 && myScopes[0].id) {
      navigate('/ops/' + myScopes[0].id);
    }
  }, [scope, myScopes]);

  return (
    <div
      style={{
        margin: 24,
      }}
    >
      {isLoading && <LoadingIndicator loadingText="Loading user scopes..." />}
      {!isLoading && myScopes && myScopes.length == 0 && (
        <Alert
          message="Error"
          type="error"
          description={'You are not authorized to view this page.'}
        />
      )}
    </div>
  );
}
