import * as React from 'react';
import {
  CloudUploadOutlined,
  PlusCircleOutlined,
  WarningOutlined,
  WindowsOutlined,
} from '@ant-design/icons';
import { Collapse, Spin, Row, Col, Alert, Typography, Input } from 'antd';
import {} from '@ant-design/icons';
import { FieldProps, useFormikContext, Field as FormikField } from 'formik';
import {
  Win10ClassicIntegration,
  defaultWin10ClassicIntegration,
  IWorkflow,
} from '../../ts-models';
import { GetField } from '../../generic/field';
import { FormContainer } from '../../generic/form-container';
import { useActiveSections } from '../../generic/workflow';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { v4 as guid } from 'uuid';
import { PlatformApprovalTable } from './detail_platformApprovalTable';
import { useWorkflowDetailView } from '../../generic/use-workflow-detailview';
import Paragraph from 'antd/lib/typography/Paragraph';
import { IntuneMobileAppNotes } from '../../generic/intuneMobileAppNotes';
import { win10ClassicUrl } from '..';

export const Field = GetField<Win10ClassicIntegration>();

export function Win10ClassicIntegrationDetailView() {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <FormContainer<Win10ClassicIntegration>
      baseUrl="/api/integrations/win10-classic"
      defaultValue={defaultWin10ClassicIntegration}
      navigateBackAction={() => {
        navigate(win10ClassicUrl);
      }}
      navigateAfterDeleteAction={() => {
        navigate(win10ClassicUrl);
      }}
      rootTitle={
        <>
          <WindowsOutlined /> Windows Desktop Application
        </>
      }
    >
      <CollapseContainer></CollapseContainer>
    </FormContainer>
  );
}

function CollapseContainer(this: React.Component) {
  const activeSections = useActiveSections();
  const navigate = useNavigate();
  const { id } = useParams();
  const { values } = useFormikContext<Win10ClassicIntegration>();
  const {
    value: {
      workflowSchema: data,
      entity,
      transferFolderSharePoint,
      win10Classic,
    },
  } = useWorkflowDetailView<IWorkflow>();
  const { workflowInstance } = entity;

  if (!activeSections) {
    return <Spin spinning={true}></Spin>;
  }
  return (
    <div>
      <Collapse
        defaultActiveKey={activeSections}
        key={JSON.stringify(activeSections)}
      >
        <Collapse.Panel header="Organizational data" key="OrgData">
          <div className="dyn-grid">
            <div>
              <Field name="requestID" />
              <Field name="appOwner" />
              <Field name="technicalContact" />
            </div>
            <div>
              <Field name="countryCoordinator" />
              <Field name="packagingPackageDeveloper" />
              <Field name="packagingRequestDeadline" />
            </div>
            <div>
              <Field name="packagingStartDate" />
              <Field name="packagingEndDate" />
              <Field name="pkgCompany" />
            </div>
            <div>
              <Field name="requestPriority" />
              <Field name="packagingPriority" />
            </div>
          </div>
          <Row gutter={[24, 16]}>
            <Col lg={24}>
              <Field name="description" />
              <Paragraph>
                <WarningOutlined /> Note: Please do not use quotation marks
                within product description: "
              </Paragraph>
            </Col>
          </Row>
        </Collapse.Panel>
        <Collapse.Panel header="Use case" key="UseCase">
          <Row gutter={[24, 16]}>
            <Col lg={24} xl={20}>
              <Field name="useCase" />
            </Col>
            <Col lg={24} xl={4}>
              <div>
                Within this Use Case Description, you can add some text, images
                and other resources to describe the use case of this
                application.
              </div>
            </Col>
          </Row>
        </Collapse.Panel>
        <Collapse.Panel header="Package data" key="PackageData">
          <div className="dyn-grid">
            <div>
              <Field name="manufacturer" />
              <Field name="productname" />
              <Field name="productVersion" />
              <Field name="language" />
              <Field name="architecture" />
              <Field name="requestComplexity" />
              <Field name="licenseRequired" />
            </div>
            <div>
              <Field name="packageType" />
              <Field name="packageManufacturerName" />
              <Field name="packageProductName" />
              <Field name="packageProductVersion" />
              <Field name="packagingPackageName" />
              {/* <Field
                name={"sourceFilesDirectory" ?? "sourceFilesDirDisplayName"}
              /> */}
              <Field name="sourceFilesDirDisplayName" />
              {/* <Field name="packageSccmDiskSpaceReq" /> // Currently not
            implemented, unclear if still needed */}
              <Field name="category" />
              {/* <Field name="packageSccmOs" /> // Currently not implemented, unclear if still needed */}
            </div>
            <div>
              <Alert
                message={
                  <div>
                    <h3>
                      <CloudUploadOutlined /> File transfer
                    </h3>
                    {!values.requestID ||
                    !values.manufacturer ||
                    !values.productname ||
                    !values.productVersion ? (
                      <Paragraph>
                        Please fill in the fields "Manufacturer", "Productname",
                        "Product version" and save form to use file upload.
                      </Paragraph>
                    ) : workflowInstance.currentStateId == 110 ||
                      workflowInstance.currentStateId == 120 ? (
                      <div>
                        <Paragraph>
                          If you need to upload files, please use the following
                          link:
                          <br />
                          <a
                            href={
                              transferFolderSharePoint.win10Classic || undefined
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            Upload to Sharepoint
                          </a>
                        </Paragraph>
                        <Paragraph>
                          Please create a folder with the name
                          <Paragraph
                            copyable={{
                              tooltips: false,
                              text: values.sourceFilesDirDisplayName!,
                            }}
                          >
                            <Typography.Text code={true}>
                              {values.requestID}_{values.manufacturer}_
                              {values.productname}_{values.productVersion}
                            </Typography.Text>
                          </Paragraph>
                        </Paragraph>
                      </div>
                    ) : (
                      <div>
                        <Paragraph>
                          All transfered files can be found on this site:
                          <br />
                          <a
                            href={
                              transferFolderSharePoint.win10Classic || undefined
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            Sharepoint Transfer Folders
                          </a>
                        </Paragraph>
                        <Paragraph>
                          Please search for the package folder with the name
                          <Paragraph
                            copyable={{
                              tooltips: false,
                              text: values.sourceFilesDirDisplayName!,
                            }}
                          >
                            <Typography.Text code={true}>
                              {values.sourceFilesDirDisplayName}
                            </Typography.Text>
                          </Paragraph>
                        </Paragraph>
                      </div>
                    )}
                  </div>
                }
                type="info"
                className="ant-form-item"
              />
            </div>
            <div>
              <Field name="filesUploaded" />
            </div>
            <div>
              <Field name="isSccmApplication" />
              {/* <Field name="intuneAppRef" /> */}
              <IntuneMobileAppNotes
                manufacturer={values.manufacturer}
                productName={values.productname}
                version={values.productVersion}
                languageCode={values.language?.isoCode || ''}
                architecture={values.architecture}
                simRequestId={values.requestID}
              />
            </div>
          </div>

          <div>
            <div className="ant-row ant-form-item">
              <div className="ant-col ant-form-item-label">
                <label title="Dev Folder">Dev Folder</label>
              </div>
              <div className="ant-col ant-form-item-control">
                <Input
                  className="ant-input"
                  disabled={true}
                  value={
                    (win10Classic.targetPathDev ?? '') +
                    '\\' +
                    (values?.packagingPackageName ?? '')
                  }
                />
              </div>
            </div>
            <div className="ant-row ant-form-item">
              <div className="ant-col ant-form-item-label">
                <label title="Test Folder">QA Folder</label>
              </div>
              <div className="ant-col ant-form-item-control">
                <Input
                  className="ant-input"
                  disabled={true}
                  value={
                    (win10Classic.targetPathQA ?? '') +
                    '\\' +
                    (values?.packagingPackageName ?? '')
                  }
                />
              </div>
            </div>
          </div>

          <Row gutter={[24, 16]}>
            <Col lg={3}></Col>
            <Col lg={21}>
              This description should provide the package developer with the
              information he needs to develop the software package for you.
              Please add all needed information how to install, test, uninstall
              and configure the application. For more information please read
              this&nbsp;
              <a
                href="https://hcgroupnet.sharepoint.com/:b:/r/teams/DWPApplicationMgmt/Shared%20Documents/General/Transfer/_Information/PackageRequestGuide.pdf?csf=1&web=1&e=O1a0Ha"
                target="_blank"
                rel="noreferrer"
              >
                Software Packaging Request Guide
              </a>
              .
            </Col>
          </Row>
          <Row gutter={[24, 16]}>
            <Col lg={24}>
              <Field name="packagingGuide" />
            </Col>
          </Row>
        </Collapse.Panel>
        <Collapse.Panel
          header="Package properties"
          key="PackageProps"
          className="panel-grid"
        >
          <div>
            <Field name="applicationLayer" />
            <Field name="requestSource" />
            <Field name="applicationType" />
            <Field name="updateCycle" />
          </div>
          <div>
            <Field name="appImportance" />
            <Field name="qtyInstallations" />
            {/* <Field name="uninstallBeforeDate" /> */}
          </div>
        </Collapse.Panel>
        <Collapse.Panel header="Security" key="Security">
          <div className="dyn-grid">
            <div>
              <Field name="securityTrustScore" />
            </div>
            <div>
              <Field name="securityCheckResults" />
            </div>
          </div>
          <Row gutter={[24, 16]}>
            <Col lg={24}>
              <Field name="securityCheckDocument" />
            </Col>
          </Row>
        </Collapse.Panel>
        <Collapse.Panel
          header="Target Platforms"
          key="TargetPlatforms"
          extra={
            entity.workflowInstance.currentStateId > 110 && (
              <Link
                to={`/integrations/win10-classic-platformapproval/${guid()}/${id}?create=true`}
              >
                <PlusCircleOutlined /> Create new
              </Link>
            )
          }
        >
          {id && <PlatformApprovalTable integrationId={id} />}
        </Collapse.Panel>
      </Collapse>
      {/* <FormikField>
        {({ field, form }: FieldProps<any>) => (
          <pre>{JSON.stringify({ field, form }, null, 2)}</pre>
        )}
      </FormikField> */}
    </div>
  );
}
