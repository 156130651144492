import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Win10ClassicIntegrationListView } from './list';
import { Win10ClassicIntegrationDetailView } from './detail';

import { Win10ClassicPlatformApprovalDetailView } from './detail_platformApproval';

export const constantsWin10Classic = {
  paths: {
    list: '/win10-classic/',
    id: '/win10-classic/:id',
    platformApprovalId: '/win10-classic-platformapproval/:id/:integrationId',
  },
};

const { paths } = constantsWin10Classic;

export function Win10ClassicIndexView() {
  return (
    <Routes>
      <Route path={paths.id} element={<Win10ClassicIntegrationDetailView />} />
      <Route
        path={paths.platformApprovalId}
        element={<Win10ClassicPlatformApprovalDetailView />}
      />
      <Route path={paths.list} element={<Win10ClassicIntegrationListView />} />
    </Routes>
  );
}
