import * as React from 'react';
import { Route, useNavigate, Routes, Link } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import { Layout, Menu } from 'antd';
import {
  AndroidOutlined,
  AppleOutlined,
  CodeOutlined,
  ExpandOutlined,
  MailOutlined,
  WindowsOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { constants } from './constants';
import { useState } from 'react';
import { Win10ClassicIndexView } from './win10-classic';
import { Win10AppIndexView } from './win10-app';
import { MobileAppIndexView } from './mobile-app';
import { useParams } from 'react-router';
import { BrowserExtensionIndexView } from './browser-extension';
import { getMenuItem, MenuItem } from '../generic/getMenuItem';

export const win10ClassicUrl =
  '/integrations/win10-classic?predefinedFilter=inProgress';
export const win10AppUrl =
  '/integrations/win10-app?predefinedFilter=inProgress';
export const iosAppUrl =
  '/integrations/mobile-app/ios?predefinedFilter=inProgress';
export const androidUrl =
  '/integrations/mobile-app/android?predefinedFilter=inProgress';
export const browerExtUrl =
  '/integrations/browser-extension?predefinedFilter=inProgress';

export function renderIntegrationsMenuItem(): MenuItem[] {
  return [
    getMenuItem(
      <Link to={win10ClassicUrl}>Windows Desktop Application</Link>,
      '5',
      <CodeOutlined />,
    ),
    getMenuItem(
      <Link to={win10AppUrl}>Windows App</Link>,
      '1',
      <WindowsOutlined />,
    ),
    getMenuItem(<Link to={iosAppUrl}>iOS App</Link>, '2', <AppleOutlined />),
    getMenuItem(
      <Link to={androidUrl}>Android App</Link>,
      '3',
      <AndroidOutlined />,
    ),
    getMenuItem(
      <Link to={browerExtUrl}>Browser Extension</Link>,
      '4',
      <ExpandOutlined />,
    ),
  ];

  // return (
  //   // <Menu.Item key={paths.main}>
  //   //   <Link to={paths.main}>
  //   //     <AppstoreAddOutlined size={150} /> {constants.linkName}
  //   //   </Link>
  //   // </Menu.Item>
  //   (<React.Fragment>
  //     <Menu.Item key="5" icon={<CodeOutlined />}>
  //       <Link to="./integrations/win10-classic?predefinedFilter=inProgress">
  //         Windows Desktop Application
  //       </Link>
  //     </Menu.Item>
  //     <Menu.Item key="1" icon={<WindowsOutlined />}>
  //       <Link to="./integrations/win10-app?predefinedFilter=inProgress">
  //         Windows App
  //       </Link>
  //     </Menu.Item>
  //     <Menu.Item key="8" icon={<AppleOutlined />}>
  //       <Link to="./integrations/mobile-app/ios?predefinedFilter=inProgress">
  //         iOS App
  //       </Link>
  //     </Menu.Item>
  //     <Menu.Item key="3" icon={<AndroidOutlined />}>
  //       <Link to="./integrations/mobile-app/android?predefinedFilter=inProgress">
  //         Android App
  //       </Link>
  //     </Menu.Item>
  //     <Menu.Item key="6" icon={<ExpandOutlined size={16} />}>
  //       <Link to="./integrations/browser-extension?predefinedFilter=inProgress">
  //         Browser Extension (preview)
  //       </Link>
  //     </Menu.Item>
  //   </React.Fragment>)
  // );
}

const { paths } = constants;
const { Sider, Content } = Layout;

export function IntegrationsMasterDetailView() {
  const navigate = useNavigate();
  const params = useParams();
  const [siderColapsed, setSiderColapsed] = useState(false);

  return (
    <Routes>
      <Route
        path={`${paths.main}/*`}
        element={
          <Layout>
            {/* <Sider
              style={{ background: "#fff" }}
              collapsible={true}
              collapsed={siderColapsed}
              onCollapse={() => setSiderColapsed(!siderColapsed)}
            >
              <Menu>
                <Menu.Item key="5" icon={<CodeOutlined />}>
                  <Link to="./win10-classic">Windows Desktop Application</Link>
                </Menu.Item>
                <Menu.Item key="1" icon={<WindowsOutlined />}>
                  <Link to="./win10-app">Windows App</Link>
                </Menu.Item>
                <Menu.Item key="2" icon={<AppleOutlined />}>
                  <Link to="./mobile-app/ios">iOS App</Link>
                </Menu.Item>
                <Menu.Item key="3" icon={<AndroidOutlined />}>
                  <Link to="./mobile-app/android">Android App</Link>
                </Menu.Item>
              </Menu>
            </Sider> */}
            <Layout style={{ flex: 1, flexDirection: 'column' }}>
              <Content style={{ flex: 1 }}>
                <Win10ClassicIndexView />
                <Win10AppIndexView />
                <MobileAppIndexView />
                <BrowserExtensionIndexView />
              </Content>
            </Layout>
          </Layout>
        }
      ></Route>
    </Routes>
  );
}

const Header = styled(PageHeader)``;

const MasterDetailContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10;
  flex: 1;
`;

const Container = styled.div``;
