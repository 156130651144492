import { Alert, Button, Col, Modal, Row, Select } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { useSubmit } from '../generic/glow/actions/use-submit';

import {
  AadUser,
  AssignMobileAppToUserPayload,
  AzureAdDevice,
  ManagedDevice,
  MobileApp,
} from '../ts-models';

import { Switch, Typography } from 'antd';
import { useParams } from 'react-router';
import { SelectedDevicesHandler } from './list_devices';

const { Paragraph, Text } = Typography;

export function AssignToDevice({
  devices,
  mobileApp,
  setSelectedAzureAdDevices,
}: {
  devices: AzureAdDevice[] | undefined;
  mobileApp: MobileApp | undefined;
  setSelectedAzureAdDevices: React.Dispatch<
    React.SetStateAction<AzureAdDevice[] | undefined>
  >;
}) {
  const options = [
    {
      value: 'Available',
      label: 'Install Available',
    },
    {
      value: 'Required', //TODO: Kein required auf User/Windows
      label: 'Install Required',
    },
    {
      value: 'Uninstall', //TODO: Kein uninstall auf User/Windows
      label: 'Uninstall Required',
    },
  ];

  const [submit] = useSubmit<AssignMobileAppToUserPayload>(
    `/api/operations/mobileapps/${mobileApp?.id}/assign-to-device`,
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [installIntent, setInstallIntent] = useState(options[0].value);
  const [error, setError] = useState<string | undefined>(undefined);

  const { scope } = useParams();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    let result = await submit({
      installIntent: installIntent,
      scope: scope,
      deviceIds: devices?.map((device) => device.id!),
    });
    setConfirmLoading(false);
    if (result.ok) {
      setInstallIntent(options[0].value);
      setIsModalOpen(false);
    } else {
      setError(JSON.stringify(result.error));
    }
  };

  const handleCancel = () => {
    setError(undefined);
    setInstallIntent(options[0].value);
    setIsModalOpen(false);
  };

  return (
    <>
      {devices && devices.length > 0 && mobileApp && (
        <Button type="primary" onClick={showModal}>
          Assign to {devices.length} device(s)
        </Button>
      )}
      <Modal
        title="Deploy software to device"
        open={isModalOpen}
        onOk={handleOk}
        okButtonProps={{ disabled: devices?.length == 0 }}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        width="50%"
      >
        <div style={{ marginBottom: 20, marginTop: 20 }}>
          <Row style={{ marginBottom: 12 }}>
            <Col flex="100px">
              <strong>Device</strong>
            </Col>
            <Col flex="auto">
              <SelectedDevicesHandler
                selectedAzureAdDevices={devices || []}
                setSelectedAzureAdDevices={setSelectedAzureAdDevices}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 12 }}>
            <Col flex="100px">
              <strong>Mobile App</strong>
            </Col>
            <Col flex="auto">
              {mobileApp?.publisher} {mobileApp?.displayName}
            </Col>
          </Row>
          <Row style={{ marginBottom: 12 }}>
            <Col flex="100px">
              <strong>Install Intent</strong>
            </Col>
            <Col flex="auto">
              <Select
                value={installIntent}
                size="small"
                onChange={(value) => setInstallIntent(value)}
                options={options}
                style={{ width: 180 }}
              />
            </Col>
          </Row>
        </div>

        {error && (
          <Alert
            message="Error"
            description={
              <Paragraph ellipsis={{ expandable: true, rows: 8 }}>
                {error}
              </Paragraph>
            }
            type="error"
            showIcon
          />
        )}
      </Modal>
    </>
  );
}
