import * as React from 'react';
import { Col, Modal, Row, Select } from 'antd';
import { useState } from 'react';
import { BaseIntegration, MobileApp } from '../ts-models';
import { SelectedUsersHandler } from './list_users';

export function AppDetails({
  mobileApp,
  showFullDisplayName,
}: {
  mobileApp: MobileApp;
  showFullDisplayName?: boolean;
}) {
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <div>
      <a onClick={() => setModalVisible(true)}>
        {showFullDisplayName == undefined ? (
          mobileApp.displayName
        ) : (
          <>
            {mobileApp.publisher} {mobileApp.displayName} {mobileApp.version}
          </>
        )}
      </a>
      <Modal
        title={
          mobileApp.publisher +
          ' ' +
          mobileApp.displayName +
          ' ' +
          (mobileApp.version || '')
        }
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[]}
      >
        <div style={{ marginBottom: 20, marginTop: 20 }}>
          <Row style={{ marginBottom: 12 }}>
            <Col flex="100px">
              <strong>Publisher</strong>
            </Col>
            <Col flex="auto">{mobileApp?.publisher}</Col>
          </Row>
          <Row style={{ marginBottom: 12 }}>
            <Col flex="100px">
              <strong>DisplayName</strong>
            </Col>
            <Col flex="auto">{mobileApp?.displayName}</Col>
          </Row>
          {mobileApp?.version && (
            <Row style={{ marginBottom: 12 }}>
              <Col flex="100px">
                <strong>Version</strong>
              </Col>
              <Col flex="auto">{mobileApp?.version}</Col>
            </Row>
          )}
        </div>
        <a
          href={`https://endpoint.microsoft.com/#view/Microsoft_Intune_Apps/SettingsMenu/~/2/appId/${mobileApp.id}`}
          target="_blank"
        >
          Intune Properties
        </a>
      </Modal>
    </div>
  );
}
