import { useField } from "formik"
import React from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

export function RichTextEditor({
  field,
  disabled,
}: {
  field: any
  disabled: boolean
}) {
  const [, , { setValue, setTouched }] = useField(field)

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  }

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ]

  return (
    <ReactQuill
      theme="snow"
      className={disabled ? "ql-disabled" : ""}
      value={field.value}
      onChange={field.onChange(field.name)}
      onBlur={() => {
        setTouched(true)
      }}
      readOnly={disabled}
      //modules={disabled ? { toolbar: [] } : modules}
      //formats={disabled ? [] : formats}
      placeholder={disabled ? "" : "Enter your description here"}
    />
  )
}
