import { defaultModelProperty, ModelProperty } from '../ts-models';
import { useReadContext } from './glow/ReadContextProvider';
import { WorkflowDetailView } from './workflow-detail-view';

interface ReturnType {
  property: any;
  enabled: boolean;
  mandatory: boolean;
  hidden: boolean;
}

export function useField(name: string): ReturnType {
  const { value } = useReadContext<WorkflowDetailView<any>>();
  const { model, fields } = value;
  const property = model.properties[name];
  const lowerName = name.toLowerCase();
  const field = fields.find((v) => v.name?.toLowerCase() == lowerName);
  if (field === null || field === undefined) {
    console.warn(`Could not find field '${name}'`);
    return {
      property,
      enabled: false,
      mandatory: false,
      hidden: false,
    };
  }
  return {
    property,
    enabled: field.enabled,
    mandatory: field.mandatory,
    hidden: field.hidden,
  };
}
