import { Layout, Menu, Row } from "antd"
import * as React from "react"
import styled from "styled-components"

const { Header, Sider, Content } = Layout

const MyHeader = (props: { children: any }) => (
  <Header
    style={{
      height: "auto",
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    {props.children}
  </Header>
)

const HeaderMenu = (props: { children: any }) => (
  <Menu
    theme="dark"
    mode="horizontal"
    defaultSelectedKeys={[window.location.pathname]}
  >
    {props.children}
  </Menu>
)

export const ApplicationLayout = ({
  Header,
  SideBar,
  children,
  footer,
}: {
  Header: any
  SideBar: any
  footer: any
  children: any
}) => (
  <Layout style={{ minHeight: "100vh" }}>
    {Header}
    <Layout style={{ flex: 1 }}>
      {SideBar && (
        <Sider
          width={200}
          style={{
            background: "#fff",
          }}
        >
          {SideBar}
        </Sider>
      )}
      <Layout>
        <Content style={{ flex: 1 }}>{children}</Content>
        {footer && <Row>{footer}</Row>}
      </Layout>
    </Layout>
  </Layout>
)

export const Root = styled(Layout)`
  min-height: 100vh;
`
