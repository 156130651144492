import * as React from "react"
import { Route, useNavigate, Routes, Link } from "react-router-dom"
import { PageHeader } from '@ant-design/pro-layout';
import { Button, Layout, Menu } from "antd";
import { LaptopOutlined, PartitionOutlined } from "@ant-design/icons"
import styled from "styled-components"
import { constants } from "./constants"
import { useState } from "react"

import { useParams } from "react-router"
import { getMenuItem, MenuItem } from "../generic/getMenuItem";



export function renderOperationsMenuItem() : MenuItem {

  return getMenuItem(<Link to={paths.main}>
       {constants.linkName}
    </Link>, paths.main, <LaptopOutlined size={150} />)

}

const { paths } = constants
const { Sider, Content } = Layout

export function OperationsDetailView() {
  const navigate = useNavigate()
  const params = useParams()
  const [siderColapsed, setSiderColapsed] = useState(false)

  return (
    <Routes>
      <Route
        path={`${paths.main}/*`}
        element={
          <div style={{ flex: 1, flexDirection: "column" }}>
            <Content style={{ flex: 1 }}>
              <iframe
                src="https://ops.sim.heidelbergcement.com/"
                //src="https://localhost:5010"
                style={{
                  border: "0px",
                  height: "calc((100vh - 64px) - 28px)",
                  width: "100%",
                }}
              />
            </Content>
          </div>
        }
      ></Route>
    </Routes>
  )
}

const Header = styled(PageHeader)``

const MasterDetailContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10;
  flex: 1;
`

const Container = styled.div``
