import * as React from 'react';
import { AadUser, MobileApp } from '../ts-models';
import { Table } from '../generic/table/table_antd';
import { ColumnType } from 'antd/es/table';
import { useParams } from 'react-router';
import { Column, Row } from 'react-table';
import { Checkbox, Tooltip, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { AppDetails } from './AppDetails';
import { IntegrationDetails } from './IntegrationDetails';

export function OperationsIntuneListApps({
  urlPredefinedFilter,
  setMobileApps,
  selectedMobileApps,
}: {
  urlPredefinedFilter: string;
  setMobileApps: React.Dispatch<React.SetStateAction<MobileApp[] | undefined>>;
  selectedMobileApps: MobileApp[] | undefined;
}) {
  const { scope } = useParams();
  const urlPrefix = `/api/operations/mobileApps?scope=${scope}&OperatingSystem=${urlPredefinedFilter}`;
  const { Paragraph } = Typography;

  const columns = [
    {
      Header: '',
      accessor: 'selection',
      disableSortBy: true,
      Cell: ({ value, row }: { value: any; row: Row<MobileApp> }) => (
        <Checkbox
          onChange={(e: CheckboxChangeEvent) =>
            setMobileApps(e.target.checked ? [row.original] : [])
          }
          checked={
            selectedMobileApps?.find((x) => x.id === row.original.id) !==
            undefined
          }
        ></Checkbox>
      ),
      width: 50,
    },
    {
      Header: 'Publisher',
      accessor: 'publisher',
      disableSortBy: true,
      width: 200,
    },
    {
      Header: 'DisplayName',
      accessor: 'displayName',
      disableSortBy: true,
      Cell: ({ value, row }) => <AppDetails mobileApp={row.original} />,
      width: 250,
    },
    {
      Header: 'Version',
      accessor: 'version',
      disableSortBy: true,
      width: 150,
      Cell: ({ value }) => <span>{value}</span>,
    },
    // {
    //   Header: 'Integration',
    //   accessor: 'integration',
    //   disableSortBy: true,
    //   width: 150,
    //   Cell: ({ value, row }: { value: any; row: Row<MobileApp> }) => (
    //     <span>
    //       <IntegrationDetails
    //         mobileAppId={row?.original?.id || ''}
    //         operatingSystem={urlPredefinedFilter}
    //       />
    //     </span>
    //   ),
    // },
    {
      Header: 'Description',
      accessor: 'description',
      disableSortBy: true,
      width: 190,
      Cell: ({ value }) => (
        <Tooltip title={value} placement="topLeft">
          <span>{value}</span>
        </Tooltip>
      ),
    },
    {
      Header: 'Type',
      accessor: 'oDataType',
      disableSortBy: true,
      width: 200,
      Cell: ({ value }) => <span>{value}</span>,
    },
  ] as Column<MobileApp>[];

  return (
    <Table<MobileApp>
      rowSelectionCallback={setMobileApps}
      rowSelectedKeys={selectedMobileApps?.map((app) => app.id!) || undefined}
      columns={columns}
      urlPrefix={urlPrefix}
      rowLink={undefined}
      searchPlaceHolder="Search in DisplayName, Publisher (contains)"
      selectType="Single"
    />
  );
}
