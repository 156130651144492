import * as React from 'react';
import {
  Collapse,
  Row,
  Col,
  Spin,
  Button,
  Modal,
  Alert,
  Typography,
} from 'antd';
import {
  CloudUploadOutlined,
  ExclamationCircleOutlined,
  WindowsOutlined,
} from '@ant-design/icons';
import { IWorkflow, Win10AppIntegration } from '../../ts-models';
import { GetField } from '../../generic/field';
import { FormContainer } from '../../generic/form-container';
import { defaultWin10AppIntegration } from '../../ts-models';
import { useActiveSections } from '../../generic/workflow';
import { v4 as guid } from 'uuid';
import { Field as FormikField, FieldProps, useFormikContext } from 'formik';
import { useState } from 'react';
import storeScreenshot from './img/store-screenshot.png';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useNavigate, useParams } from 'react-router';
import { useWorkflowDetailView } from '../../generic/use-workflow-detailview';
import { IntuneMobileAppNotes } from '../../generic/intuneMobileAppNotes';
import { win10AppUrl } from '..';

export const Field = GetField<Win10AppIntegration>();

function StoreInstructionsModal() {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Button type="default" onClick={() => setVisible(true)}>
        Help
      </Button>
      <Modal
        title="Import info from store"
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={806}
      >
        <p>
          To get things started please click on the "Open Microsoft Store"
          button below, search for the application you would like to request.
          Copy and paste the URL for the application, the name, the manufacturer
          and license information (see yellow marker at "Instagram" example
          below).{' '}
        </p>
        <p>
          To easy search the store click on any appliction and then enter the
          application name in the sarch field. ATTENTION: Please select only
          applications which are Available on PC!
        </p>
        <p>
          <img src={storeScreenshot} style={{ width: '100%' }} />
        </p>
        <a
          href="https://www.microsoft.com/en-us/store/apps/windows?icid=CNavAppsWindowsApps"
          target="_blank"
          rel="noreferrer"
        >
          Open Store
        </a>
      </Modal>
    </>
  );
}

export function Win10AppIntegrationDetailView() {
  const { id } = useParams();
  const navigate = useNavigate();
  return (
    <FormContainer<Win10AppIntegration>
      key={id}
      baseUrl="/api/integrations/win10-app"
      defaultValue={defaultWin10AppIntegration}
      navigateBackAction={() => {
        navigate(win10AppUrl);
      }}
      navigateAfterDeleteAction={() => {
        navigate(win10AppUrl);
      }}
      rootTitle={
        <>
          <WindowsOutlined /> Windows App
        </>
      }
    >
      <CollapseContainer></CollapseContainer>
    </FormContainer>
  );
}

function CollapseContainer(this: React.Component) {
  const activeSections = useActiveSections();
  const { values } = useFormikContext<Win10AppIntegration>();
  const {
    value: { workflowSchema: data, entity, transferFolderSharePoint },
  } = useWorkflowDetailView<IWorkflow>();
  const { workflowInstance } = entity;

  if (!activeSections) {
    return <Spin spinning={true}></Spin>;
  }
  return (
    <div>
      <Collapse
        defaultActiveKey={activeSections}
        key={JSON.stringify(activeSections)}
      >
        <Collapse.Panel
          header="Organizational data"
          key="OrgData"
          className="panel-grid"
        >
          <div>
            <Field name="appOwner" />
            <Field name="technicalContact" />
          </div>
          <div>
            <Field name="countryCoordinator" />
            <Field name="requestPriority" />
          </div>
          <div>
            <Field name="testingResponsible" />
          </div>
          <div>
            <Field name="requestID" />
          </div>
        </Collapse.Panel>
        <Collapse.Panel
          header="Application data"
          key="AppData"
          className="panel-grid"
        >
          <div>
            <div style={{ display: 'flexbox' }}>
              <Row gutter={[24, 16]}>
                <Col md={24} lg={18}>
                  <Field name="applicationTypeMDM" />
                </Col>
                <Col md={24} lg={6}>
                  {values.applicationTypeMDM == 'Public' && (
                    <StoreInstructionsModal />
                  )}
                </Col>
              </Row>
            </div>
            {values.applicationTypeMDM != null && (
              <Field name="licensingModel" />
            )}
          </div>
          {values.applicationTypeMDM != null && (
            <>
              <div>
                <Field name="manufacturer" />
                <Field name="productname" />
              </div>
              <div>
                <Field name="licenseRequired" />
              </div>
              <div>
                <Field name="productVersion" />
                {values.applicationTypeMDM == 'Public' && (
                  <Field name="appStoreProductLink" />
                )}
              </div>
              {values.applicationTypeMDM == 'Internal' &&
                (workflowInstance.currentStateId == 100 ||
                  workflowInstance.currentStateId == 200 ||
                  workflowInstance.currentStateId == 700) && (
                  <div>
                    <Alert
                      message={
                        <div>
                          <h3>
                            <CloudUploadOutlined /> File upload
                          </h3>
                          {!values.requestID ||
                          !values.manufacturer ||
                          !values.productname ||
                          !values.productVersion ? (
                            <Paragraph>
                              Please fill in the fields "Manufacturer",
                              "Productname", "Product version" and save form to
                              use file upload.
                            </Paragraph>
                          ) : (
                            <div>
                              <Paragraph>
                                If you need to upload files, please use the
                                following link:
                                <br />
                                <a
                                  href={
                                    transferFolderSharePoint.win10Modern ||
                                    undefined
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Upload to Sharepoint
                                </a>
                              </Paragraph>
                              <Paragraph>
                                Please create a folder with the name
                                <Paragraph
                                  copyable={{
                                    tooltips: false,
                                    text: values.sourceFilesDirDisplayName!,
                                  }}
                                >
                                  <Typography.Text code={true}>
                                    {workflowInstance.currentStateId == 100
                                      ? `${values.requestID}_${values.manufacturer}_${values.productname}_${values.productVersion}`
                                      : values.sourceFilesDirDisplayName}
                                  </Typography.Text>
                                </Paragraph>
                              </Paragraph>
                            </div>
                          )}
                        </div>
                      }
                      type="info"
                    />
                  </div>
                )}

              {values.applicationTypeMDM == 'Internal' && (
                <div>
                  <Field name="filesUploaded" />
                </div>
              )}
            </>
          )}
        </Collapse.Panel>
        <Collapse.Panel
          header="Application properties"
          key="AppProps"
          className="panel-grid"
        >
          <div>
            <Field name="clientPlatform" />
            <Field name="requestSource" />
          </div>
          <div>
            <Field name="updateCycle" />
            <Field name="qtyInstallations" />
          </div>
          <div>
            <Field name="appImportance" />
            <Field name="applicationLayer" />
          </div>
          <div>
            <Field name="cloudServicesUsed" />
            <Field name="deployedIn" />
          </div>
        </Collapse.Panel>
        <Collapse.Panel header="Use Case" key="UseCase">
          <Row gutter={[24, 16]}>
            <Col lg={24} xl={20}>
              <Field name="useCase" />
            </Col>
            <Col lg={24} xl={4}>
              <div>
                Within this Use Case Description, you can add some text, images
                and other resources to describe the use case of this
                application.
              </div>
            </Col>
          </Row>
        </Collapse.Panel>
        <Collapse.Panel header="Additional information" key="AppInfos">
          <div className="dyn-grid">
            <div>
              <Field name="languageSupported" />
              <Field name="aprxDownloadSize" />
            </div>
            <div>
              <Field name="rating" />
              <Field name="availableOn" />
            </div>
            <div>
              <Field name="category" />
              <Field name="businessAppStoreProductLink" />
            </div>
            <div>
              <Field name="osMinimum" />
              <Field name="architectureMinimum" />
              <Field name="memoryMinimum" />
            </div>
          </div>
          <Row gutter={[24, 16]}>
            <Col lg={24}>
              <Field name="appPermissions" />
            </Col>
          </Row>
          <Row gutter={[24, 16]}>
            <Col lg={24}>
              <Field name="description" />
            </Col>
          </Row>
        </Collapse.Panel>
        <Collapse.Panel
          header="Provisioning (MSFB / MECM)"
          key="Provisioning"
          className="panel-grid"
        >
          {workflowInstance.currentStateId == 400 && (
            <Alert
              style={{ marginBottom: 24 }}
              message={
                <div>
                  <h3>
                    <ExclamationCircleOutlined /> Hold on!
                  </h3>

                  <div>
                    <Paragraph>
                      Please follow manual tasks below before proceeding with
                      workflow:
                      <br />- Please copy following string to{' '}
                      <strong>MECM Application </strong>
                      administrative comments field:
                      <br />
                      <Paragraph
                        copyable={{
                          tooltips: false,
                          text: 'Request ID: ' + values.requestID,
                        }}
                      >
                        <Typography.Text code={true}>
                          Request ID: {values.requestID}
                        </Typography.Text>
                      </Paragraph>
                    </Paragraph>
                  </div>
                </div>
              }
              type="info"
            />
          )}
          <IntuneMobileAppNotes
            manufacturer={values.manufacturer}
            productName={values.productname}
            version={values.productVersion}
            languageCode={''}
            architecture={''}
            simRequestId={values.requestID}
          />
          <div>
            <Field name="ticketID" />
            <Field name="ticketResponsible" />
          </div>

          <div>
            <Field name="provisionedInMSFB" />
            <Field name="provisionedInSCCM" />
          </div>
          <div>
            <Field name="applicationReference" />
          </div>
          {/* <div>
            <Row gutter={[24, 16]}>
              <Col lg={24}>
                <Field name="intuneAppRef" />
              </Col>
            </Row>
          </div>*/}
        </Collapse.Panel>
        <Collapse.Panel header="Security" key="Security">
          <div className="dyn-grid">
            <div>
              <Field name="securityTrustScore" />
            </div>
            <div>
              <Field name="securityCheckResults" />
            </div>
          </div>
          <Row gutter={[24, 16]}>
            <Col lg={24}>
              <Field name="securityCheckDocument" />
            </Col>
          </Row>
        </Collapse.Panel>
        <Collapse.Panel header="Approval" key="Approval">
          <div className="dyn-grid">
            <div>
              <Field name="approvalDeadline" />
            </div>
            <div>
              <Field name="functionTestPassed" />
            </div>
          </div>
          <Row gutter={[24, 16]}>
            <Col lg={24}>
              <Field name="functionTestDocument" />
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>

      {/* <FormikField>
        {({ field, form }: FieldProps<any>) => (
          <pre>{JSON.stringify({ field, form }, null, 2)}</pre>
        )}
      </FormikField> */}
    </div>
  );
}
