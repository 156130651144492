import * as React from "react"
import { Route, Routes } from "react-router-dom"

import { BrowserExtensionIntegrationListView } from "./list"
import { BrowserExtensionIntegrationDetailView } from "./detail"

const constants = {
  paths: {
    list: "/browser-extension/",
    id: "/browser-extension/:id",
  },
}

const { paths } = constants

export function BrowserExtensionIndexView() {
  return (
    <Routes>
      <Route
        path={paths.id}
        element={<BrowserExtensionIntegrationDetailView />}
      />
      <Route
        path={paths.list}
        element={<BrowserExtensionIntegrationListView />}
      />
    </Routes>
  )
}
