import * as React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export function LoadingIndicator({
  loadingText,
}: {
  loadingText?: string | undefined;
}) {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <span>
      <Spin indicator={antIcon} /> {loadingText || 'Loading...'}
    </span>
  );
}
