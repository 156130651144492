import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Alert, Typography } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import * as React from 'react';

export function IntuneMobileAppNotes({
  manufacturer,
  productName,
  version,
  languageCode,
  architecture,
  simRequestId,
}: {
  manufacturer: string | null;
  productName: string | null;
  version: string | null;
  languageCode: string | null;
  architecture: string | null;
  simRequestId: string | null;
}) {
  const copyText = `{
    "manufacturer":"${manufacturer}",
    "productName":"${productName}",
    "version":"${version}",
    "languageCode":"${languageCode}",
    "architecture":"${architecture}", 
    "simRequestId":"${simRequestId}"
}`;

  return (
    <Alert
      style={{ marginBottom: 24 }}
      message={
        <div>
          <h3>
            <ExclamationCircleOutlined /> Hold on!
          </h3>

          <div>
            <Paragraph>
              Please follow manual tasks below before proceeding with workflow:
              <br />- Please copy following string to{' '}
              <strong>Intune Application</strong> notes field:
              <br />
              <Paragraph
                copyable={{
                  tooltips: false,
                  text: copyText,
                }}
              >
                <Typography.Text code={true}>{copyText}</Typography.Text>
              </Paragraph>
            </Paragraph>
          </div>
        </div>
      }
      type="info"
    />
  );
}
