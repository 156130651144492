import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Win10AppIntegrationListView } from './list';
import { Win10AppIntegrationDetailView } from './detail';

export const constantsWin10App = {
  paths: {
    list: '/win10-app/',
    id: '/win10-app/:id',
  },
};

const { paths } = constantsWin10App;

export function Win10AppIndexView() {
  return (
    <Routes>
      <Route path={paths.id} element={<Win10AppIntegrationDetailView />} />
      <Route path={paths.list} element={<Win10AppIntegrationListView />} />
    </Routes>
  );
}
