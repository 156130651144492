import { Empty, Pagination, Space, Spin } from 'antd';
import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';
import {
  Column,
  SortingRule,
  TableOptions,
  useBlockLayout,
  useExpanded,
  useFilters,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import {
  DownSquareOutlined,
  LoadingOutlined,
  UpSquareOutlined,
} from '@ant-design/icons';
import Search from 'antd/lib/input/Search';
import { QueryResult } from '../../ts-models-custom';
import debounce from 'lodash.debounce';
import { useData } from '../glow/actions/use-data';

export interface TableProperties<T extends Record<keyof T, unknown>>
  extends TableOptions<T> {
  name: string;
  pageSize: number;
  totalRows: number;
  currentPage: number;
  status: 'loading' | 'error' | 'success' | 'idle';
  rowLink: ((data: T) => string) | undefined;
  onChangeSortBy: (sortingRole: SortingRule<any>[]) => void;
  onChangePageIndex: (pageIndex: number) => void;
  onChangePageSize: (pageSize: number) => void;
}

export function Table<T extends Record<keyof T, unknown>>({
  urlPrefix,
  columns,
  rowLink,
  extraSearch,
  searchPlaceHolder,
}: {
  urlPrefix: string;
  columns: Column<T>[];
  rowLink: ((data: T) => string) | undefined;
  extraSearch?: JSX.Element;
  searchPlaceHolder?: string | undefined;
}) {
  const [searchString, setSearchString] = useState<string>('');
  const [orderByColumnName, setOrderByColumnName] = useState<string>('');
  const [orderDirectionIsAsc, setOrderDirectionIsAsc] = useState<boolean>(true);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const url = `${urlPrefix}${
    urlPrefix.includes('?') ? '&' : '?'
  }SearchString=${searchString}&OrderByColumn=${orderByColumnName}&OrderDirectionIsAsc=${orderDirectionIsAsc}&skip=${
    (pageIndex - 1) * pageSize
  }&take=${pageSize}`;
  const {
    data: queryResult,
    loading,
    error,
    status,
  } = useData<QueryResult<T>>(url, {
    count: 0,
    value: [],
  });

  const onChangeSortBy = (sortingRole: SortingRule<any>[]) => {
    setOrderDirectionIsAsc(!sortingRole[0].desc);
    setOrderByColumnName(sortingRole[0].id);
  };

  const onChangePageIndex = (pageIndex: number) => {
    setPageIndex(pageIndex);
  };

  const onChangePageSize = (pageSize: number) => {
    setPageSize(pageSize);
  };

  const debouncedSearch = debounce((search) => {
    setPageIndex(0);
    setSearchString(search);
  }, 300);

  React.useEffect(() => {
    setPageIndex(1);
  }, [urlPrefix]);

  const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

  return (
    <Container>
      {error ? <div>ERROR: {JSON.stringify(error)}</div> : <div></div>}
      <SearchContainer>
        <Space direction="horizontal">
          {extraSearch}
          <Search
            placeholder={searchPlaceHolder || 'input search text'}
            allowClear
            enterButton="Search"
            size="middle"
            onSearch={debouncedSearch}
            style={{ width: 400 }}
          />
          <span>{loading && <Spin indicator={antIcon} />}</span>
        </Space>
      </SearchContainer>

      <TableCore<T>
        data={queryResult.value}
        status={status}
        totalRows={queryResult.count}
        columns={columns}
        name="table"
        currentPage={pageIndex}
        pageSize={pageSize}
        rowLink={rowLink}
        onChangeSortBy={onChangeSortBy}
        onChangePageIndex={onChangePageIndex}
        onChangePageSize={onChangePageSize}
      />
    </Container>
  );
}

const SearchContainer = styled.div`
  margin-bottom: 10px;
  width: 400px;
`;

const Container = styled.div`
  padding: 20px;
`;

export type TableCoreProps = {
  mode?: 'pagination' | 'load_more';
};

export function TableCore<T extends Record<keyof T, unknown>>(
  props: TableCoreProps & React.PropsWithChildren<TableProperties<T>>,
): ReactElement {
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 184,
      //maxWidth: 400,
    }),
    [],
  );

  const hooks = [
    useFilters,

    useSortBy,
    useExpanded,
    useFlexLayout,
    usePagination,
    useResizeColumns,

    useBlockLayout,
  ];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,

    state: { sortBy },
  } = useTable<T>(
    {
      ...props,
      defaultColumn,
      manualSortBy: true,
      manualPagination: true,
      pageCount: 4,
    },
    ...hooks,
  );

  let showPaginationChanger = false;
  if (props.totalRows > 10) {
    showPaginationChanger = true;
  }

  useEffect(() => {
    if (sortBy.length > 0) {
      props.onChangeSortBy(sortBy);
    }
  }, [sortBy]);

  return (
    <div>
      <AntdTableStyle>
        <div className="table-container" style={{ backgroundColor: 'white' }}>
          <div {...getTableProps()} className="table">
            <div className="thead">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="tr">
                  {headerGroup.headers.map((column) => (
                    <div {...column.getHeaderProps()} className="th">
                      {column.render('Header')}
                      {column.canSort && (
                        <span>
                          <span
                            {...column.getSortByToggleProps()}
                            className="sorter"
                          >
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <DownSquareOutlined />
                              ) : (
                                <UpSquareOutlined />
                              )
                            ) : (
                              <span className="sortOnHover">
                                {' '}
                                <span className="sorterIcon">
                                  <UpSquareOutlined />
                                </span>
                              </span>
                            )}
                          </span>
                          {/* Use column.getResizerProps to hook up the events correctly */}
                        </span>
                      )}
                      <div
                        {...column.getResizerProps()}
                        className={`resizer ${
                          column.isResizing ? 'isResizing' : ''
                        }`}
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>

            <div {...getTableBodyProps()} className="tbody">
              {rows.map((row, i) => {
                prepareRow(row);
                return props.rowLink ? (
                  <Link to={props.rowLink(props.data[i])}>
                    <div {...row.getRowProps()} className="tr">
                      {row.cells.map((cell) => {
                        return (
                          <div {...cell.getCellProps()} className="td">
                            {cell.render('Cell')}
                          </div>
                        );
                      })}
                    </div>
                  </Link>
                ) : (
                  <div {...row.getRowProps()} className="tr">
                    {row.cells.map((cell) => {
                      return (
                        <div {...cell.getCellProps()} className="td">
                          {cell.render('Cell')}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
          {props.status == 'success' && props.totalRows == 0 && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
        {props.mode === 'pagination' ||
          (props.mode === undefined && (
            <Pagination
              className="pagination"
              total={props.totalRows}
              current={props.currentPage}
              pageSize={props.pageSize}
              onChange={(page, pageSize) => {
                props.onChangePageIndex(page);
              }}
              showSizeChanger={showPaginationChanger}
              onShowSizeChange={(current, size) => {
                props.onChangePageSize(size);
              }}
              showTotal={() => `Total ${props.totalRows} items`}
            />
          ))}
      </AntdTableStyle>
    </div>
  );
}

const AntdTableStyle = styled.div`
  .table-container {
    overflow-y: hidden;
    overflow-x: auto;

    .table {
      box-shadow: 0 5px 8px rgb(0 0 0 / 9%), 0 3px 3px rgb(0 0 0 / 13%);
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.85);
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      -webkit-font-feature-settings: 'tnum';
      font-feature-settings: 'tnum', 'tnum';
      position: relative;
      z-index: 0;
      font-size: 14px;
      background: #fff;
      border-radius: 2px;
      display: inline-block;
      min-width: 100% !important;

      .thead {
        background: #fafafa;
        border-bottom: 1px solid #f0f0f0;
        .th {
          color: rgba(0, 0, 0, 0.85);
          font-weight: 500;
          text-align: left;
          background: #fafafa;

          -webkit-transition: background 0.3s ease;
          transition: background 0.3s ease;
          position: relative;
          padding: 17px;
          overflow-wrap: break-word;

          .resizer {
            display: inline-block;

            width: 6px;
            height: 100%;

            position: absolute;
            right: 0;
            top: 0;
            transform: translateX(50%);
            z-index: 1;
            border-left: 1px solid rgb(0 0 0 / 10%);
            &:hover {
              border-left: 1px solid rgb(0 0 0 / 20%);
              border-right: 1px solid rgb(0 0 0 / 20%);
            }

            ${'' /* prevents from scrolling while dragging on touch devices */}
            touch-action:none;

            &.isResizing {
              border-left: 1px solid rgb(0 0 0 / 50%);
              border-right: 1px solid rgb(0 0 0 / 50%);
            }
          }
        }
      }

      ,
      .tbody {
        .tr {
          .td {
            border-bottom: 1px solid #f0f0f0;
            -webkit-transition: background 0.3s;
            transition: background 0.3s;
            position: relative;
            padding: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            ${
              '' /* In this example we use an absolutely position resizer,
           so this is required. */
            }
            position: relative;
            color: #272727;
          }
          &:hover {
            background-color: #fafafa;
            color: #272727;
          }
        }
      }
    }
  }
`;
