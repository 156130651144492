import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import { MobileAppIntegrationListView } from './list';
import { MobileAppIntegrationDetailView } from './detail';

export const constantsMobileApp = {
  paths: {
    list: '/mobile-app/:clientPlatform',
    id: '/mobile-app/:clientPlatform/:id',
  },
};

const { paths } = constantsMobileApp;

export function MobileAppIndexView() {
  return (
    <Routes>
      <Route path={paths.id} element={<MobileAppIntegrationDetailView />} />
      <Route path={paths.list} element={<MobileAppIntegrationListView />} />
    </Routes>
  );
}
