import * as React from 'react';
import {
  Collapse,
  Row,
  Col,
  Spin,
  Button,
  Modal,
  Typography,
  Space,
} from 'antd';
import {
  BrowserExtensionIntegration,
  defaultBrowserExtensionIntegration,
  IWorkflow,
} from '../../ts-models';
import { GetField } from '../../generic/field';
import { FormContainer } from '../../generic/form-container';
import { useActiveSections } from '../../generic/workflow';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useWorkflowDetailView } from '../../generic/use-workflow-detailview';
import { ExpandOutlined } from '@ant-design/icons';
import { browerExtUrl } from '..';

export const Field = GetField<BrowserExtensionIntegration>();

function StoreInstructionsModal({
  clientPlatform,
}: {
  clientPlatform: 'Chrome' | 'Edge';
}) {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Button type="default" onClick={() => setVisible(true)}>
        Help
      </Button>
      <Modal
        title="Import info from store"
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={806}
      >
        <h3>Find new extensions</h3>

        {clientPlatform == 'Edge' && (
          <div>
            <Space size="middle" direction="vertical">
              <div>
                {' '}
                <Button
                  type="primary"
                  onClick={() =>
                    window.open(
                      'https://microsoftedge.microsoft.com/addons/Microsoft-Edge-Extensions-Home?hl=en-US',
                      '_blank',
                    )
                  }
                >
                  Get extensions for Microsoft Edge
                </Button>
              </div>
              <div>
                To see installed Edge extensions open{' '}
                <a href="about:blank" target="_blank" rel="noreferrer">
                  new window
                </a>{' '}
                and browse to
                <Typography.Text code={true} copyable={true}>
                  edge://extensions
                </Typography.Text>
              </div>
              <div>
                You can't find what you are looking for? You can also get
                extensions from the{' '}
                <a
                  href="https://chrome.google.com/webstore/category/extensions?hl=en-US"
                  target="_blank"
                  rel="noreferrer"
                >
                  Chrome Web Store
                </a>
              </div>
            </Space>
          </div>
        )}

        {clientPlatform == 'Chrome' && (
          <div>
            <Space size="middle" direction="vertical">
              <div>
                {' '}
                <Button
                  type="primary"
                  onClick={() =>
                    window.open(
                      'https://chrome.google.com/webstore/category/extensions?hl=en-US',
                      '_blank',
                    )
                  }
                >
                  Get extensions for Google Chrome
                </Button>
              </div>
              <div>
                To see installed Chrome extensions open{' '}
                <a href="about:blank" target="_blank" rel="noreferrer">
                  new window
                </a>{' '}
                and browse to
                <Typography.Text code={true} copyable={true}>
                  chrome://extensions
                </Typography.Text>
              </div>
            </Space>
          </div>
        )}
      </Modal>
    </>
  );
}

export function BrowserExtensionIntegrationDetailView() {
  const { id, clientPlatform } = useParams();
  const navigate = useNavigate();
  return (
    <FormContainer<BrowserExtensionIntegration>
      key={id}
      baseUrl="/api/integrations/browser-extension"
      defaultValue={defaultBrowserExtensionIntegration}
      navigateBackAction={() => {
        navigate(browerExtUrl);
      }}
      navigateAfterDeleteAction={() => {
        navigate(browerExtUrl);
      }}
      rootTitle={
        <Space size="small" direction="horizontal" align="center">
          {/* <IoExtensionPuzzleOutline className="anticon anticon-windows" /> */}
          <ExpandOutlined />
          <div> Browser Extension</div>
        </Space>
      }
    >
      <CollapseContainer></CollapseContainer>
    </FormContainer>
  );
}

function CollapseContainer(this: React.Component) {
  const activeSections = useActiveSections();
  const { values } = useFormikContext<BrowserExtensionIntegration>();
  const {
    value: { workflowSchema: data, entity, transferFolderSharePoint },
  } = useWorkflowDetailView<IWorkflow>();
  const { workflowInstance } = entity;

  if (!activeSections) {
    return <Spin spinning={true}></Spin>;
  }
  return (
    <div>
      <Collapse
        defaultActiveKey={activeSections}
        key={JSON.stringify(activeSections)}
      >
        <Collapse.Panel
          header="Organizational data"
          key="OrgData"
          className="panel-grid"
        >
          <div>
            <Field name="appOwner" />
            <Field name="technicalContact" />
          </div>
          <div>
            <Field name="countryCoordinator" />
            <Field name="requestPriority" />
          </div>
          <div>
            <Field name="testingResponsible" />
          </div>
          <div>
            <Field name="requestID" />
          </div>
        </Collapse.Panel>
        <Collapse.Panel
          header="Application data"
          key="AppData"
          className="panel-grid"
        >
          <div>
            <div style={{ display: 'flexbox' }}>
              <Row gutter={[24, 16]}>
                <Col md={24} lg={18}>
                  <Field name="clientPlatform" />
                </Col>
                <Col md={24} lg={6}>
                  <StoreInstructionsModal
                    clientPlatform={values.clientPlatform}
                  />
                </Col>
              </Row>
            </div>
          </div>

          <>
            <div>
              <Field name="manufacturer" />
              <Field name="productname" />
            </div>
            <div>
              <Field name="licenseRequired" />
              <Field name="trustedManufacturer" />
            </div>
            <div>
              <Field name="appStoreProductLink" />
            </div>
          </>
        </Collapse.Panel>
        <Collapse.Panel
          header="Application properties"
          key="AppProps"
          className="panel-grid"
        >
          <div>
            <Field name="requestSource" />
          </div>
          <div>
            <Field name="updateCycle" />
            <Field name="qtyInstallations" />
          </div>
          <div>
            <Field name="appImportance" />
            <Field name="applicationLayer" />
          </div>
          <div>
            <Field name="cloudServicesUsed" />
            <Field name="deployedIn" />
          </div>
        </Collapse.Panel>
        <Collapse.Panel header="Use Case" key="UseCase">
          <Row gutter={[24, 16]}>
            <Col lg={24} xl={20}>
              <Field name="useCase" />
            </Col>
            <Col lg={24} xl={4}>
              <div>
                Within this Use Case Description, you can add some text, images
                and other resources to describe the use case of this
                application.
              </div>
            </Col>
          </Row>
        </Collapse.Panel>
        <Collapse.Panel
          header="Data Protection Assessment - Data collection"
          key="DataProtectionInfoCollection"
        >
          <div>
            <h2>Data Protection Assessment - Web Browser Extensions</h2>
            <p>
              If the web browser extension processes any personal data, please
              contact the manufacturer, and ask him to answer the following
              questions that apply to the extension.
              <br />
              Please transfer the answers to the form below. (Download the
              questionary here{' '}
              <a
                href="https://hcgroupnet.sharepoint.com/:b:/r/teams/DWPApplicationMgmt/Shared%20Documents/General/Transfer/_Information/InfoSec_DataProtection/BrowserExtension_DPassessment.pdf?csf=1&web=1&e=RgbaEU"
                target="_blank"
                rel="noreferrer"
              >
                Web Browser Extension Assessment
              </a>
              )
            </p>

            <p>
              Note: "processing" means any operation or set of operations which
              is performed on personal data or on sets of personal data, whether
              or not by automated means, such as collection, recording,
              organization, structuring, storage, adaptation or alteration,
              retrieval, consultation, use, disclosure by transmission,
              dissemination or otherwise making available, alignment or
              combination, restriction, erasure, or destruction.
            </p>
          </div>
          <div>
            <Field name="dataProtectionAssessmentData" />
          </div>
          <div>
            <Field name="dataProtectionFormAnswered" />
          </div>
        </Collapse.Panel>
        <Collapse.Panel
          header="Data Protection Assessment - Data evaluation"
          key="DataProtectionInfoEvaluation"
        >
          <div>
            <Field name="dataProtectionAssessmentComment" />
            <Field name="dataProtectionCheckResults" />
          </div>
        </Collapse.Panel>
        <Collapse.Panel header="Additional information" key="AppInfos">
          <div className="dyn-grid">
            <div>
              <Field name="aprxDownloadSize" />
            </div>
            <div>
              <Field name="category" />
            </div>
            <div></div>
          </div>
          <Row gutter={[24, 16]}>
            <Col lg={24}>
              <Field name="appPermissions" />
            </Col>
          </Row>
          <Row gutter={[24, 16]}>
            <Col lg={24}>
              <Field name="description" />
            </Col>
          </Row>
        </Collapse.Panel>
        <Collapse.Panel
          header="Provisioning"
          key="Provisioning"
          className="panel-grid"
        >
          <div>
            <Field name="ticketID" />
            <Field name="ticketResponsible" />
          </div>
          <div>
            <Field name="provisionedInGPO" />
          </div>
          <div></div>
          <div></div>
        </Collapse.Panel>
        <Collapse.Panel header="Security" key="Security">
          <div className="dyn-grid">
            <div>
              <Field name="securityTrustScore" />
            </div>
            <div>
              <Field name="securityCheckResults" />
            </div>
          </div>
          <Row gutter={[24, 16]}>
            <Col lg={24}>
              <Field name="securityCheckDocument" />
            </Col>
          </Row>
        </Collapse.Panel>
        <Collapse.Panel header="Approval" key="Approval">
          <div className="dyn-grid">
            <div>
              <Field name="approvalDeadline" />
            </div>
            <div>
              <Field name="functionTestPassed" />
            </div>
          </div>
          <Row gutter={[24, 16]}>
            <Col lg={24}>
              <Field name="functionTestDocument" />
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}
