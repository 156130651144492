import * as React from "react"
import { useState } from "react"

export type ReadContextType<T> = {
  value: T
  reload: () => void
}

export const readContext = React.createContext<ReadContextType<any>>(
  null as any,
)

export function useReadContext<T>() {
  const ctx = React.useContext(readContext)
  if (ctx == null) {
    throw "cannot read context"
  }
  return ctx as ReadContextType<T>
}

export function ReadContextProvider<T>({
  value,
  reload,
  children,
}: {
  value: T
  reload: () => void
  children: JSX.Element | JSX.Element[]
}) {
  //   const [_value, setValue] = useState<T>(value)
  //   const [_reload, setReload] = useState<() => void>(reload)

  return (
    <readContext.Provider value={{ value: value, reload: reload }}>
      {children}
    </readContext.Provider>
  )
}
