import * as React from 'react';
import { Route, useNavigate, Routes, Link } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import { Layout, Menu } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { constants } from './constants';
import { useState } from 'react';

import { useParams } from 'react-router';
import { CountryPortfolioListView } from '../countryPortfolio/list';
import { CountryPortfolioDetailView } from './detail';
import { MenuItem, getMenuItem } from '../generic/getMenuItem';

export function renderCountryPortfolioMenuItem(): MenuItem {
  return getMenuItem(
    <Link to={paths.main}>{constants.linkName}</Link>,
    paths.main,
    <GlobalOutlined size={150} />,
  );
}

const { paths } = constants;
const { Sider, Content } = Layout;

export function CountryPortfolioMasterDetailView() {
  const navigate = useNavigate();
  const params = useParams();
  const [siderColapsed, setSiderColapsed] = useState(false);

  return (
    <Routes>
      <Route
        path={`${paths.main}/*`}
        element={
          <Layout>
            {/* <Sider
              style={{ background: "#fff" }}
              collapsible={true}
              collapsed={siderColapsed}
              onCollapse={() => setSiderColapsed(!siderColapsed)}
            >
              <Menu>
                <Menu.Item key="5" icon={<CodeOutlined />}>
                  <Link to="./win10-classic">Windows Desktop Application</Link>
                </Menu.Item>
                <Menu.Item key="1" icon={<WindowsOutlined />}>
                  <Link to="./win10-app">Windows App</Link>
                </Menu.Item>
                <Menu.Item key="2" icon={<AppleOutlined />}>
                  <Link to="./mobile-app/ios">iOS App</Link>
                </Menu.Item>
                <Menu.Item key="3" icon={<AndroidOutlined />}>
                  <Link to="./mobile-app/android">Android App</Link>
                </Menu.Item>
              </Menu>
            </Sider> */}
            <Layout style={{ flex: 1, flexDirection: 'column' }}>
              <Content style={{ flex: 1 }}>
                <Routes>
                  <Route
                    path={paths.id}
                    element={<CountryPortfolioDetailView />}
                  />

                  <Route
                    path={paths.list}
                    element={<CountryPortfolioListView />}
                  />
                </Routes>
              </Content>
            </Layout>
          </Layout>
        }
      ></Route>
    </Routes>
  );
}

const Header = styled(PageHeader)``;

const MasterDetailContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10;
  flex: 1;
`;

const Container = styled.div``;
