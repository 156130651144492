import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { Divider, Space } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { PredefinedFiltersUi } from '../../ts-models';
import { useQuery } from '../use-query';

export function PredefinedFilters({
  filters,
  urlSetter,
}: {
  filters: PredefinedFiltersUi[];
  urlSetter: (urlPredefinedFilter: string | undefined) => void;
}) {
  let query = useQuery();

  const [predefinedFilter, setPredefinedFilter] = useState<
    string | undefined
  >();

  React.useEffect(() => {
    if (predefinedFilter) {
      if (query.get('predefinedFilter') != null) {
        urlSetter(query.get('predefinedFilter') || filters[0].key);
      }
    } else {
      if (query.get('predefinedFilter') != null) {
        setPredefinedFilter(query.get('predefinedFilter')?.toString());
      } else {
        urlSetter(filters[0].key);
        setPredefinedFilter(filters[0].key);
      }
    }
  }, [predefinedFilter]);

  return (
    <Space
      size="middle"
      direction="horizontal"
      align="center"
      style={{ marginTop: 7 }}
      //split={<Divider type="vertical" />}
    >
      {filters.map((filter) => {
        return (
          <Link
            to={`?predefinedFilter=${filter.key}`}
            onClick={() => setPredefinedFilter(filter.key)}
            style={{ color: '#333333' }}
            key={filter.key}
          >
            {predefinedFilter == filter.key ? (
              <FilterFilled />
            ) : (
              <FilterOutlined />
            )}{' '}
            {filter.label}
          </Link>
        );
      })}
    </Space>
  );
}
