import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  AndroidOutlined,
  AppleOutlined,
  CodeOutlined,
  GlobalOutlined,
  WindowsOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';

import {
  Country,
  CountryPortfolioListDto,
  GenericIntegration,
  Win10ClassicPlatform,
} from '../ts-models';

import { Column } from 'react-table';
import { Table } from '../generic/table/table';
import { PredefinedFilters } from '../generic/table/predefined-filters';
import { useState } from 'react';
import { SelectFromApi } from '../generic/remote-select';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import { Col, Row, Space, Tag } from 'antd';

export function CountryPortfolioListView() {
  const { clientPlatform } = useParams();

  const navigate = useNavigate();
  const urlPrefix = `/api/country-portfolio/list`;

  const [urlPredefinedFilter, setUrlPredefinedFilter] = useState<
    string | undefined
  >();
  const [countryFilterId, setCountryFilterId] = useState<string | undefined>();
  const [w10platformFilterId, setW10platformFilterId] = useState<
    string | undefined
  >();

  const columns = [
    {
      Header: 'Manufacturer',
      accessor: 'genericIntegration.manufacturer',
      disableSortBy: true,
      width: 190,
      //Cell: ({ value }) => value || "?",
    },
    {
      Header: 'Product Name',
      accessor: 'genericIntegration.productname',
      disableSortBy: true,
      width: 290,
      //Cell: ({ value }) => value || "?",
    },
    {
      Header: 'Product Version',
      accessor: 'genericIntegration.productVersion',
      disableSortBy: true,
      width: 150,
      //Cell: ({ value }) => value || "?",
    },
    {
      Header: 'Request Id',
      accessor: 'genericIntegration.requestID',
      disableSortBy: true,
      width: 100,
    },
    {
      Header: 'Language',
      accessor: 'genericIntegration.language.isoCode',
      disableSortBy: true,
      width: 80,
      Cell: ({ value }: { value: string }) => value || 'n/a',
    },
    {
      Header: 'Architecture',
      accessor: 'genericIntegration.architecture',
      disableSortBy: true,
      width: 80,
      Cell: ({ value }: { value: string }) => value || 'n/a',
    },

    {
      Header: 'Platform',
      accessor: 'genericIntegration.type',
      disableSortBy: true,
      width: 390,
      Cell: ({ value, row }: { value: string; row: any }) => (
        <AppTypeIcon genericIntegration={row.original.genericIntegration} />
      ),
    },
    // {
    //   Header: 'Status',
    //   accessor: 'genericIntegration.workflowInstance.currentStateName',
    //   disableSortBy: true,
    // },
    // {
    //   Header: 'Count',
    //   id: 'country-count',
    //   accessor: 'countries',
    //   disableSortBy: true,
    //   Cell: ({ value }: { value: Country[] }) => (
    //     <span>
    //       {value.length == 63 ? (
    //         <span>
    //           <GlobalOutlined style={{ color: '#058137' }} /> {value.length}{' '}
    //         </span>
    //       ) : (
    //         value.length
    //       )}
    //     </span>
    //   ),
    // },
    // {
    //   Header: 'Assigned countries',
    //   id: 'country-list',
    //   disableSortBy: true,
    //   accessor: 'countries',
    //   Cell: ({ value }: { value: Country[] }) => (
    //     <span>
    //       {value.map((country) => {
    //         return country.isoCode + ', ';
    //       })}
    //     </span>
    //   ),
    // },
  ] as Column<CountryPortfolioListDto>[];

  return (
    <div>
      <Header
        title={
          <div>
            <span>
              <GlobalOutlined /> Country Portfolio
            </span>
          </div>
        }
        extra={[
          <PredefinedFilters
            filters={[
              { label: 'All applications', key: 'allPackages' },
              //{ label: "Active Packages", key: "activePackages" },
              { label: 'My Applications', key: 'myPackages' },
            ]}
            urlSetter={setUrlPredefinedFilter}
          />,
        ]}
      />

      {urlPredefinedFilter && (
        <Table<CountryPortfolioListDto>
          columns={columns}
          urlPrefix={
            urlPrefix +
            `?urlPredefinedFilter=${
              urlPredefinedFilter ? urlPredefinedFilter : ''
            }` +
            `&extrafilter=${countryFilterId || ''}` +
            `&platformFilter=${w10platformFilterId || ''}`
          }
          searchPlaceHolder={'Search Manufacturer, ProductName'}
          rowLink={(row) => `./${row.id}`}
          // orderBy="genericIntegration.manufacturer"
          extraSearch={
            <Formik initialValues={[]} onSubmit={() => {}}>
              <Form>
                <Row style={{ width: 570 }}>
                  <Col style={{ marginRight: 10 }}>
                    <SelectFromApi
                      placeholder="(Choose country...)"
                      name="countrySearch"
                      backingField="countrySearch"
                      url={'api/countries/countryPortfolio'}
                      //disabled={w10platformFilterId != undefined}
                      onChange={(e) => {
                        setCountryFilterId(e?.value || undefined);
                      }}
                      style={{ width: 250 }}
                    />
                  </Col>
                  <Col>
                    <SelectFromApi
                      placeholder="(Choose platform...)"
                      name="win10classicplatform"
                      backingField="win10classicplatform"
                      url={'api/country-portfolio/platforms'}
                      //disabled={countryFilterId != undefined}
                      onChange={(e) => {
                        setW10platformFilterId(e?.value || undefined);
                      }}
                      style={{ width: 250 }}
                    />
                  </Col>
                </Row>
              </Form>
            </Formik>
          }
        />
      )}
    </div>
  );
}

export function AppTypeIcon({
  genericIntegration,
}: {
  genericIntegration: GenericIntegration;
}) {
  return (
    <span>
      {genericIntegration.type == 'Win10ClassicIntegration' ? (
        <span>
          <CodeOutlined /> Windows Desktop Application{' '}
          <>
            {genericIntegration.win10ClassicPlatformApprovalsProductive && (
              <Space size={[0, 'small']} wrap>
                {genericIntegration.win10ClassicPlatformApprovalsProductive.map(
                  (platform: any) => (
                    <Tag bordered={false}>{platform.osCode}</Tag>
                  ),
                )}
              </Space>
            )}
          </>
        </span>
      ) : genericIntegration.type == 'iOS' ? (
        <span>
          <AppleOutlined /> iOS App
        </span>
      ) : genericIntegration.type == 'Android' ? (
        <span>
          <AndroidOutlined /> Android App
        </span>
      ) : (
        <span>
          <WindowsOutlined /> Windows App
        </span>
      )}
    </span>
  );
}

const Header = styled(PageHeader)`
  background: #fff;
`;
