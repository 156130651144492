import * as React from 'react';
import { useLocation, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import { Descriptions, Button, Row, Col, Table, Card } from 'antd';
import {
  BarsOutlined,
  CaretLeftOutlined,
  CheckCircleOutlined,
  CloseSquareOutlined,
  DoubleRightOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { EyeTwoTone } from '@ant-design/icons';
import { useState } from 'react';
import { ActionPanel } from '../generic/action-panel';
import {
  defaultTransferFolderSharePoint,
  defaultWin10Classic,
  defaultWorkflowSchema,
  IEntity,
  IWorkflow,
  TransitionValidationResult,
  TransitionWithAction,
} from '../ts-models';
import { WorkflowDetailView } from './workflow-detail-view';
import { defaultModel, defaultState } from '../ts-models-custom';
import { DateTime } from 'luxon';
import { LoadingIndicator } from './loadingIndicator';
import { useData } from './glow/actions/use-data';
import { useSubmit } from './glow/actions/use-submit';
import { ReadContextProvider } from './glow/ReadContextProvider';

// baseurl example: '/api/integrations/win10-classic'
//
export function FormContainer<T extends IEntity & IWorkflow>({
  baseUrl,
  children,
  defaultValue,
  navigateBackAction,
  navigateAfterDeleteAction,
  rootTitle,
}: React.PropsWithChildren<{
  baseUrl: string;
  defaultValue: T;
  navigateBackAction?: () => void;
  navigateAfterDeleteAction?: () => void;
  rootTitle: JSX.Element;
}>) {
  const { id, integrationId, clientPlatform } = useParams();
  let location = useLocation();
  const [fullInfoVisible, setFullInfoVisible] = useState<boolean>(false);

  const { data: detailView, refetch: refetchDetailview, status } = useData<
    WorkflowDetailView<T>
  >(
    `${baseUrl}/${id}/detail-view?clientPlatform=${clientPlatform}&parentId=${integrationId}&create=${location.search.includes(
      'create=true',
    )}`,
    {
      entity: defaultValue as any,
      fields: [],
      model: defaultModel,
      workflowSchema: defaultWorkflowSchema,
      currentState: defaultState,
      currentStatePolicies: [],
      currentStateUserHasPermission: false,
      loading: true,
      transferFolderSharePoint: defaultTransferFolderSharePoint,
      win10Classic: defaultWin10Classic,
      history: [],
    },
  );
  const { entity, history } = detailView;

  const [submit, validate] = useSubmit(
    `${baseUrl}/update?create=${location.search.includes('create=true')}`,
  );

  const [submitValidate] = useSubmit<TransitionValidationResult>(
    `${baseUrl}/validate-transition`,
  );
  const { workflowInstance, ...formData } = entity;

  const navigate = useNavigate();

  return (
    <ReadContextProvider<WorkflowDetailView<T>>
      value={detailView}
      reload={refetchDetailview}
    >
      {/* <pre>{JSON.stringify(schema, null, 4)}</pre> */}

      <HeaderTop
        title={
          <div>
            <span>{rootTitle}</span>
            <Button
              icon={<CaretLeftOutlined />}
              onClick={() =>
                navigateBackAction ? navigateBackAction() : navigate('..')
              }
              style={{ marginLeft: 10, marginRight: 10 }}
              size="small"
            />{' '}
            {entity.displayName}
          </div>
        }
        extra={[
          <Button
            key="1"
            hidden={fullInfoVisible}
            icon={<EyeTwoTone />}
            size="small"
            //type="link"
            onClick={() => setFullInfoVisible(!fullInfoVisible)}
          >
            See extended info / history
          </Button>,
        ]}
      />

      <Container>
        {fullInfoVisible && (
          <>
            <Card
              bordered={true}
              size="small"
              style={{ border: '1px solid #d9d9d9', borderRadius: 2 }}
              title="Extended info"
              extra={
                <Button
                  type="link"
                  icon={<CloseSquareOutlined />}
                  onClick={() => setFullInfoVisible(false)}
                ></Button>
              }
            >
              <Descriptions size="small" column={4} bordered={false}>
                <Descriptions.Item label="Created">
                  {entity.createdBy}
                </Descriptions.Item>
                <Descriptions.Item label="Creation time">
                  {DateTime.fromISO(entity.createdAt).toLocaleString(
                    DateTime.DATETIME_SHORT,
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Updated">
                  {entity.updatedBy}
                </Descriptions.Item>
                <Descriptions.Item label="Update time">
                  {DateTime.fromISO(entity.updatedAt).toLocaleString(
                    DateTime.DATETIME_SHORT,
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Card>
            <Card
              bordered={true}
              size="small"
              style={{ border: '1px solid #d9d9d9', borderRadius: 2 }}
              title="History"
            >
              <EntityHistory executedTransitions={history} />
            </Card>
          </>
        )}

        <Formik
          initialValues={formData}
          enableReinitialize={true}
          validate={async (values) => {
            const response = await validate(values);
            return response;
          }}
          onSubmit={async (values) => {
            const result = await submit(values);
            if (result.ok) {
              refetchDetailview();
            } else {
            }
            return result;
          }}
          validateOnBlur={true}
          validateOnChange={false}
        >
          <Form layout="horizontal" colon={false}>
            {status == 'loading' ? (
              <LoadingIndicator />
            ) : (
              <Row gutter={[24, 16]}>
                <Col md={24} lg={18}>
                  {children}
                </Col>
                <Col md={24} lg={6}>
                  <ActionPanel
                    baseUrl={baseUrl}
                    navigateAfterDeleteAction={
                      navigateAfterDeleteAction
                        ? navigateAfterDeleteAction
                        : navigateBackAction
                    }
                  />
                </Col>
              </Row>
            )}
          </Form>
        </Formik>
      </Container>
    </ReadContextProvider>
  );
}

function EntityHistory({
  executedTransitions,
}: {
  executedTransitions: TransitionWithAction[];
}) {
  return (
    <Table<TransitionWithAction>
      //loading={loading}
      bordered={false}
      size="small"
      style={{ flex: 1 }}
      // onRow={(record) => ({
      //   onClick: () =>
      //     navigate(
      //       `/integrations/win10-classic-platformapproval/${record.id}/${integrationId}`,
      //     ),
      // })}
      rowKey={(row: { id: any }) => row.id!}
      // components={{
      //   body: {
      //     row: (props: any) => <HighlightableRow path="/MobileAppIntegration/" {...props} />,
      //   },
      // }}
      columns={[
        {
          title: 'Timestamp (UTC)',
          key: 'triggeredAt',
          render: (record: TransitionWithAction) => (
            <span>
              {DateTime.fromISO(record.triggeredAt).toLocaleString(
                DateTime.DATETIME_SHORT,
              )}
            </span>
          ),
          sorter: (a: { triggeredAt: string }, b: { triggeredAt: string }) =>
            DateTime.fromISO(a.triggeredAt).toSeconds() -
            DateTime.fromISO(b.triggeredAt).toSeconds(),
          sortDirections: ['descend', 'ascend'],
          defaultSortOrder: 'descend',
          width: 180,
        },
        {
          title: 'Triggered by',
          key: 'triggeredBy',
          render: (record: TransitionWithAction) => (
            <span>{record.triggeredBy}</span>
          ),
          width: 200,
        },
        {
          title: 'Action',
          key: 'fromStateDisplayName',
          render: (record: TransitionWithAction) =>
            record.action ? (
              <span>
                {record.isSuccessful ? (
                  <CheckCircleOutlined
                    style={{ color: '#3d8060', marginLeft: 23, marginRight: 8 }}
                  />
                ) : (
                  <ExclamationCircleOutlined
                    style={{ color: '#c70505', marginLeft: 23, marginRight: 8 }}
                  />
                )}
                {record.action}
              </span>
            ) : (
              record.fromStateDisplayName && (
                <span>
                  <BarsOutlined style={{ marginRight: 8 }} />
                  {record.fromStateDisplayName}{' '}
                  <DoubleRightOutlined
                    style={{ fontSize: 10, color: '#afa3a3' }}
                  />{' '}
                  {record.toStateDisplayName}
                </span>
              )
            ),
          width: 400,
        },

        {
          title: 'Message',
          key: 'message',
          render: (record: TransitionWithAction) => (
            <span>{record.message}</span>
          ),
          ellipsis: true,
        },
      ]}
      dataSource={executedTransitions ? executedTransitions : []}
      pagination={
        executedTransitions && executedTransitions.length > 10
          ? {
              pageSize: 10,
            }
          : false
      }
    />
  );
}

const Header = styled(PageHeader)`
  padding: 0px;
  margin-bottom: 20px;
`;

const Container = styled.div`
  padding: 20px;
`;

const HeaderTop = styled(PageHeader)`
  background: #fff;
`;
