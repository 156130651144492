export const constants = {
  linkName: "Multi Target",
  paths: {
    main: "/multi-target",
    list: "/",
    create: "/create",
    id: "/:id",
    main_request_id: "/request/:id/:parentId",
  },
}
