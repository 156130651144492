import * as React from 'react';
import { useState } from 'react';
import { Menu, Avatar, Divider, Switch, Modal, Button, Collapse } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LoginOutlined,
  LogoutOutlined,
  ProfileOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { UserPhoto, AuthorizeUserPolicy } from '../ts-models';
import { defaultUserClaim, Profile, UserClaim } from '../ts-models-custom';
import { RenderRoleIcon } from './workflow';
import { useData } from './glow/actions/use-data';
import { getMenuItem, MenuItem } from './getMenuItem';

export function ProfileHandler() {
  const { data: user } = useData<Profile>(`/glow/profile`, {
    displayName: null,
    id: null,
    email: null,
    identityName: null,
    isAuthenticated: false,
    userId: null,
  } as Profile);

  const { data: userClaims } = useData<UserClaim[]>(`/glow/profile/claims`, [
    defaultUserClaim,
  ]);

  return user?.isAuthenticated ? (
    <ExtInfo profile={user} claims={userClaims} />
  ) : (
    <a href="/account/signin">Login</a>
  );
}

// <MenuItem
//   key="99"
//   style={{ float: 'right', textAlign: 'right', minWidth: 300 }}
//   title={user.displayName}
//   icon={
//     userPhoto.available ? (
//       <img src={`data:image/png;base64,userPhoto.base64`} />
//     ) : (
//       <Avatar
//         src={`https://eu.ui-avatars.com/api/?background=e6e6e6&color=333333&name=${user.displayName}&rounded=true&size=32`}
//         style={{ marginRight: 20 }}
//       />
//     )
//   }
// >
//   {/* <Menu.Item key="signout">
//     <a href="/account/signout">
//       <LogoutOutlined size={150} /> Logout
//     </a>
//   </Menu.Item> */}
//   <Menu.Item key="myaccount">
//     <a href="https://myaccount.microsoft.com/" target="_blank" rel="noreferrer">
//       <UserOutlined size={150} /> My Account
//     </a>
//   </Menu.Item>

//   <Divider style={{ marginTop: 10, marginBottom: 10 }} />

//   <div
//     style={{
//       marginLeft: 16,
//       marginRight: 16,
//       paddingBottom: 16,
//       paddingTop: 16,
//       fontSize: 14,
//       display: 'grid',
//       gridTemplateColumns: '50% 50%',
//     }}
//   >
//     <div>
//       <ProfileOutlined style={{ marginRight: 10 }} /> Roles
//       <ExtInfo profile={user} claims={userClaims} />
//     </div>
//     <div style={{ justifySelf: 'end', marginTop: -8 }}>
//       <Switch
//         //size="small"
//         defaultChecked={false}
//         onChange={showRoles}
//         unCheckedChildren={<EyeOutlined />}
//         checkedChildren={<EyeInvisibleOutlined />}
//       />
//     </div>
//   </div>
//   <div
//     style={{
//       marginLeft: 16,
//       marginRight: 16,
//       paddingBottom: 16,
//       paddingTop: 0,
//     }}
//   >
//     {userRoles?.map((role) =>
//       role.userHasPermission ? (
//         <div style={{ marginTop: 5 }}>
//           <RenderRoleIcon authorizeUserPolicy={role} />
//           &nbsp;
//           <span style={{}}>{role.name}</span>
//         </div>
//       ) : allRolesVisible ? (
//         <div style={{ marginTop: 5 }}>
//           <RenderRoleIcon authorizeUserPolicy={role} />
//           &nbsp;
//           <span style={{ color: `#bbb` }}>{role.name}</span>
//         </div>
//       ) : (
//         <></>
//       ),
//     )}
//   </div>
// </MenuItem>

function ExtInfo({
  profile,
  claims,
}: {
  profile: Profile;
  claims: UserClaim[];
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const { data: userPhoto } = useData<UserPhoto>(`/api/my/photo`, {
    available: false,
    base64: null,
  });
  const { data: userRoles } = useData<AuthorizeUserPolicy[]>(
    `/api/my/roles`,
    [],
  );

  const [allRolesVisible, setRoleVisibilty] = useState(false);
  function showRoles(checked: boolean) {
    setRoleVisibilty(checked);
  }
  return (
    <span style={{ color: '#e7e7e7' }}>
      <Button
        onClick={() => setModalVisible(true)}
        type="text"
        size="large"
        style={{ color: 'white', fontSize: 14 }}
        icon={
          <span style={{ marginRight: 10 }}>
            {userPhoto.available ? (
              <img src={`data:image/png;base64,userPhoto.base64`} />
            ) : (
              <Avatar
                src={`https://eu.ui-avatars.com/api/?background=e6e6e6&color=333333&name=${profile.displayName}&rounded=true&size=24`}
                size="small"
              />
            )}
          </span>
        }
      >
        {profile.displayName}
      </Button>

      <Modal
        title="Profile"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[]}
      >
        <div>
          <h2>{profile.displayName}</h2>
          {/* <a href="/account/signout">
            <LogoutOutlined size={150} /> Logout
          </a> */}

          <a
            href="https://myaccount.microsoft.com/"
            target="_blank"
            rel="noreferrer"
            style={{ marginLeft: 20 }}
          >
            <UserOutlined size={150} /> My Account
          </a>

          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          {/* <Switch
            //size="small"
            defaultChecked={false}
            onChange={showRoles}
            unCheckedChildren={<EyeOutlined />}
            checkedChildren={<EyeInvisibleOutlined />}
          /> */}
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexGrow: 1,
                flexShrink: 1,
                marginBottom: 10,
              }}
            >
              <div style={{ fontSize: 16, fontWeight: 500 }}>Roles</div>
              {!allRolesVisible && (
                <Button
                  size="small"
                  icon={<EyeOutlined />}
                  onClick={() => setRoleVisibilty(true)}
                >
                  Show all roles
                </Button>
              )}
            </div>
            {userRoles?.map((role) =>
              role.userHasPermission ? (
                <div style={{ marginTop: 5 }}>
                  <RenderRoleIcon authorizeUserPolicy={role} />
                  &nbsp;
                  <span style={{}}>{role.name}</span>
                </div>
              ) : allRolesVisible ? (
                <div style={{ marginTop: 5 }}>
                  <RenderRoleIcon authorizeUserPolicy={role} />
                  &nbsp;
                  <span style={{ color: `#bbb` }}>{role.name}</span>
                </div>
              ) : (
                <></>
              ),
            )}
          </div>
        </div>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <Collapse
          defaultActiveKey={['1']}
          ghost={true}
          style={{ marginLeft: -16, marginRight: -16 }}
        >
          <Collapse.Panel header="Extended profile info" key="2">
            <code>
              <pre style={{ maxHeight: 400, display: 'block' }}>
                {JSON.stringify(
                  {
                    displayName: profile.displayName,
                    id: profile.id,
                    email: profile.email,
                    identityName: profile.identityName,
                    userId: profile.userId,
                    claims: claims,
                  },
                  null,
                  1,
                )}
              </pre>
            </code>
          </Collapse.Panel>
        </Collapse>
      </Modal>
    </span>
  );
}
