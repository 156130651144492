import * as React from "react"
import { Divider, Button, Popconfirm } from "antd"
import { DeleteOutlined } from "@ant-design/icons"
import { SubmitButton } from "formik-antd"
import { BaseIntegration, WorkflowInstance } from "../ts-models"
import {
  CodeTwoTone,
  EditTwoTone,
  DeleteTwoTone,
  ReloadOutlined,
} from "@ant-design/icons"
import {
  WorkflowAction,
  WorkflowActionButtons,
  WorkflowControls,
  WorkflowPermissions,
  WorkflowSteps,
} from "./workflow"
import { useWorkflowDetailView } from "./use-workflow-detailview"
import { Delete } from "./deleteButton"

export function ActionPanel({
  baseUrl,
  navigateAfterDeleteAction,
}: {
  baseUrl: string
  navigateAfterDeleteAction?: () => void
}) {
  const {
    value: { currentStateUserHasPermission },
    reload,
  } = useWorkflowDetailView<BaseIntegration>()
  return (
    <div className="formControlArea">
      <div className="formActions">
        <h3>Actions</h3>

        <Delete
          baseUrl={baseUrl}
          navigateAfterDeleteAction={navigateAfterDeleteAction}
        />
        <Button icon={<ReloadOutlined />} type="link" onClick={() => reload()}>
          Refresh
        </Button>
        <SubmitButton
          icon={<EditTwoTone />}
          type="link"
          disabled={!currentStateUserHasPermission}
        >
          Save
        </SubmitButton>

        <WorkflowActionButtons baseUrl={baseUrl} />

        <Divider plain={true} orientation="left">
          Workflow handling
        </Divider>
        <WorkflowControls baseUrl={baseUrl} />
        {/* <Divider plain={true} orientation="left">
          Scripts
        </Divider> */}
        {/* <Button icon={<CodeTwoTone />} type="link">
          Create SCCM Application
        </Button> */}
        {/* <WorkflowAction
          baseUrl={baseUrl}
          runActionRequest={{ action: "Test" }}
          title="Test Script"
        /> */}
        {/*
        <WorkflowAction
          baseUrl={baseUrl}
          runActionRequest={{ action: "CreateApplication" }}
          title="Create SCCM Application"
        />{" "}
        <WorkflowAction
          baseUrl={baseUrl}
          sendMailRequest={{ mailTemplate: "Test" }}
          title="Send App Owner Mail"
        /> */}
      </div>
      <div className="formWorkflow">
        <WorkflowSteps baseUrl={baseUrl} />
      </div>
      <div>
        <WorkflowPermissions />
      </div>
    </div>
  )
}
