import * as React from "react"
import { Form } from "formik-antd"
import { Editor } from "./editor"
import { useField } from "./use-field"
import { useFormikContext } from "formik"
import styled from "styled-components"
import { Spin, Tooltip, Typography } from "antd"
import { LoadingOutlined } from "@ant-design/icons"

interface DynamicProps<T> {
  name: keyof T
  referenceId?: string
}

function Dynamic<T>(props: DynamicProps<T>) {
  const name = props.name as string
  const { property, enabled, mandatory, hidden } = useField(name)
  const { status } = useFormikContext<any>()
  const { type } = property

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

  if (!property) {
    return <Spin indicator={antIcon} />
  }

  if (hidden) {
    return <div></div>
  }

  if (property.tooltip) {
    return (
      <div>
        <Form.Item name={name} label={property.label} required={mandatory}>
          <Tooltip title={property.tooltip}>
            <Editor
              name={name}
              property={property}
              disabled={!enabled}
              referenceId={props.referenceId}
            />
            {status && status[name] && (
              <FieldError>Error: {status[name]}</FieldError>
            )}
          </Tooltip>
        </Form.Item>
      </div>
    )
  }

  return (
    <div>
      <Form.Item name={name} label={property.label} required={mandatory}>
        <Editor
          name={name}
          property={property}
          disabled={!enabled}
          referenceId={props.referenceId}
        />
        {status && status[name] && (
          <FieldError>Error: {status[name]}</FieldError>
        )}
      </Form.Item>
      {/* <pre>{JSON.stringify({ enabled, ...property }, null, 4)}</pre> */}
    </div>
  )
}

export function GetField<T>() {
  return Dynamic as (props: DynamicProps<T>) => JSX.Element
}

const FieldError = styled.div`
  color: red;
  // font-weight: bold;
`
