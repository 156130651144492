import {
  Button,
  Empty,
  Input,
  InputRef,
  Pagination,
  Space,
  Spin,
  Table as TableAntd,
} from 'antd';
import * as React from 'react';
import { ReactElement, useEffect, useRef, useState } from 'react';
import './table_antd.css';

import type { ResizeCallbackData } from 'react-resizable';
import { Resizable } from 'react-resizable';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import {
  DownSquareOutlined,
  LoadingOutlined,
  SearchOutlined,
  UpSquareOutlined,
} from '@ant-design/icons';
import Search from 'antd/lib/input/Search';
import { QueryResult } from '../../ts-models-custom';
import debounce from 'lodash.debounce';
import { useData } from '../glow/actions/use-data';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { FilterConfirmProps } from 'antd/es/table/interface';
import { TableCore } from './table';
import { Column, SortingRule } from 'react-table';

export interface TableProperties<T extends Record<keyof T, unknown>> {
  name: string;
  pageSize: number;
  status: 'loading' | 'error' | 'success' | 'idle';
  rowLink: ((data: T) => string) | undefined;
}

export function Table<T extends { [key: string]: any }>({
  urlPrefix,
  columns,
  rowLink,
  extraSearch,
  rowSelectedKeys,
  rowSelectionCallback,
  searchPlaceHolder,
  selectType,
}: {
  urlPrefix: string;
  columns: Column<T>[];
  rowLink: ((data: T) => string) | undefined;
  extraSearch?: JSX.Element;
  rowSelectedKeys?: React.Key[] | undefined;
  rowSelectionCallback?: (row: T[]) => void;
  searchPlaceHolder?: string | undefined;
  selectType: 'Single' | 'Multi';
}) {
  const [searchString, setSearchString] = useState<string>('');
  const [totalRows, setTotalRows] = useState<number>(0);
  const [skiptoken, setSkiptoken] = useState<string>('');
  const [url, setUrl] = useState<string>(BuildUrl(urlPrefix, searchString, ''));
  const [data, setData] = useState<T[]>([]);
  const [_columns, setColumns] = useState<ColumnsType<T>>([]);

  const {
    data: queryResult,
    loading,
    error,
    status,
  } = useData<QueryResult<T>>(url, {
    count: 0,
    value: [],
    skipToken: '',
  });

  useEffect(() => {
    setData([]);
    setTotalRows(0);
    setUrl(BuildUrl(urlPrefix, searchString, ''));
  }, [urlPrefix]);

  useEffect(() => {
    setData((oldArray) => [...oldArray, ...queryResult.value]);
    queryResult.count > 0 && setTotalRows(queryResult.count);
  }, [queryResult]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: T[]) => {
      rowSelectionCallback && rowSelectionCallback(selectedRows);
    },
    getCheckboxProps: (record: T) => ({
      disabled: false, // Column configuration not to be checked
      name: JSON.stringify(record),
    }),
  };

  const debouncedSearch = debounce((search) => {
    if (searchString != search) {
      setData([]);
      setUrl(BuildUrl(urlPrefix, search, ''));
      setSearchString(search);
    }
  }, 300);

  return (
    <Container>
      {error ? <div>ERROR: {JSON.stringify(error)}</div> : <div></div>}
      <SearchContainer>
        <Space direction="horizontal">
          {extraSearch}
          <Search
            placeholder={searchPlaceHolder || 'input search text'}
            allowClear
            enterButton="Search"
            size="middle"
            onSearch={debouncedSearch}
            style={{ width: 400 }}
          />
          <span>
            {loading && (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
              />
            )}
          </span>
        </Space>
      </SearchContainer>
      <TableCore<T>
        data={data}
        status={status}
        totalRows={data.length}
        columns={columns}
        name="table"
        currentPage={0}
        pageSize={0}
        rowLink={rowLink}
        onChangeSortBy={() => {}}
        onChangePageIndex={() => {}}
        onChangePageSize={() => {}}
        mode={'load_more'}
      />
      <div style={{ marginTop: 8 }}>
        Showing {data.length} of {totalRows}
        {queryResult.skipToken && (
          <Button
            onClick={() =>
              setUrl(BuildUrl(urlPrefix, searchString, queryResult.skipToken))
            }
            style={{ marginLeft: 10 }}
          >
            Load more
          </Button>
        )}
      </div>
    </Container>
  );
}

const ResizableTitle = (
  props: React.HTMLAttributes<any> & {
    onResize: (
      e: React.SyntheticEvent<Element>,
      data: ResizeCallbackData,
    ) => void;
    width: number;
  },
) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={10}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: true }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

function BuildUrl(
  urlPrefix: string,
  searchString: string,
  skiptoken: string | undefined,
): string {
  return `${urlPrefix}${
    urlPrefix.includes('?') ? '&' : '?'
  }SearchString=${searchString}&skiptoken=${skiptoken}`;
}

const SearchContainer = styled.div`
  margin-bottom: 10px;
  width: 400px;
`;

const Container = styled.div`
  padding: 20px;
`;
