import { Alert, Button, Col, Modal, Row, Select, Skeleton } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { useSubmit } from '../generic/glow/actions/use-submit';

import {
  AadUser,
  AssignMobileAppToUserPayload,
  defaultUserScope,
  MobileApp,
  PredefinedFiltersUi,
  SccmApplication,
  IntegrationDetails as IntegrationDetailsType,
  SccmUser,
} from '../ts-models';

import { Switch, Typography } from 'antd';
import { useParams } from 'react-router';
import { SelectedUsersHandler } from './list_users';
import { UserTable } from './UserTable';
import { useData } from '../generic/glow/actions/use-data';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Flex } from 'antd/lib';

const { Paragraph, Text } = Typography;

export function AssignToUser({
  users,
  mobileApp,
  platform,
  setSelectedUsers,
}: {
  users: AadUser[];
  mobileApp: MobileApp | undefined;
  platform: 'ios' | 'microsoft' | 'android';
  setSelectedUsers: React.Dispatch<React.SetStateAction<AadUser[]>>;
}) {
  // const { data: queryResult, loading, error, status } = useData<QueryResult<T>>(
  //     url,
  //     {
  //       count: 0,
  //       value: [],
  //     },
  //   )

  const [submit] = useSubmit<AssignMobileAppToUserPayload>(
    `/api/operations/mobileapps/${mobileApp?.id}/assign-to-user`,
  );
  const [mecmUsers, setMecmUsers] = useState<SccmUser[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [installIntent, setInstallIntent] = useState('Available');
  const [error, setError] = useState<string | undefined>(undefined);
  const [sccmApplicationDetails, setSccmApplicationDetails] = useState<
    SccmApplication | undefined
  >();
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    [],
  );

  const { scope } = useParams();

  React.useEffect(() => {
    let _options = [
      {
        value: 'Available',
        label: 'Install Available',
      },
    ];

    if (platform == 'ios' || platform == 'android') {
      _options.push({
        value: 'Required',
        label: 'Install Required',
      });
      _options.push({
        value: 'Uninstall',
        label: 'Uninstall Required',
      });
    }
    setOptions(_options);
  }, [platform]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    let result = await submit({
      installIntent: installIntent,
      scope: scope,
      userIds: users?.map((x) => x.id),
    });
    setConfirmLoading(false);
    if (result.ok) {
      handleCancel();
    } else {
      setError(JSON.stringify(result.error.detail));
    }
  };

  const handleCancel = () => {
    setError(undefined);
    setIsModalOpen(false);
    setInstallIntent(options[0].value);
  };

  return (
    <>
      {users && users.length > 0 && mobileApp && (
        <Button type="primary" onClick={showModal}>
          Assign to {users?.length} user(s)
        </Button>
      )}
      <Modal
        title="Deploy software to user"
        open={isModalOpen}
        onOk={handleOk}
        okButtonProps={{ disabled: users?.length == 0 }}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        width="60%"
      >
        <div style={{ marginBottom: 20, marginTop: 20 }}>
          <Row style={{ marginBottom: 32 }}>
            {/* <Col flex="100px">
              <strong>User</strong>
            </Col>
            <Col flex="auto">
              <SelectedUsersHandler
                selectedUsers={users || []}
                setSelectedUsers={setSelectedUsers}
              />
            </Col> */}
            {users && (
              <UserTable
                selectedUsers={users}
                setSelectedUsers={setSelectedUsers}
                platform={platform}
                mecmUsers={mecmUsers}
                setMecmUsers={setMecmUsers}
              />
            )}
          </Row>
          <Flex gap="large" vertical={true}>
            <Flex gap="large" vertical={false}>
              <strong>Intune Application</strong>
              {mobileApp?.publisher} {mobileApp?.displayName}
              <Select
                value={installIntent}
                size="small"
                onChange={(value) => setInstallIntent(value)}
                options={options}
              />
              {/* <Button onClick={() => handleOk()} type="primary" size="small">
                Deploy to Intune
              </Button> */}
            </Flex>
            {/* {platform == 'microsoft'  && (
              <Flex gap="large" vertical={false}>
                <strong>MECM Application</strong>
                <div>
                  {mobileApp?.id && (
                    <SccmAppDetails
                      mobileAppId={mobileApp?.id}
                      platform={platform}
                      setSccmApplicationDetails={setSccmApplicationDetails}
                    />
                  )}
                </div>
                <Select
                  value={installIntent}
                  size="small"
                  onChange={(value) => setInstallIntent(value)}
                  options={options}
                  disabled={sccmApplicationDetails == null}
                />
                <Button
                  onClick={() => handleOk()}
                  type="primary"
                  size="small"
                  disabled={sccmApplicationDetails == null}
                >
                  Deploy to MECM
                </Button>
              </Flex>
            )} */}
          </Flex>
        </div>

        {error && (
          <Alert
            message="Error"
            description={
              <Paragraph ellipsis={{ expandable: true, rows: 8 }}>
                {error}
              </Paragraph>
            }
            type="error"
            showIcon
          />
        )}
      </Modal>
    </>
  );
}

export function SccmAppDetails({
  mobileAppId,
  platform,
  setSccmApplicationDetails,
}: {
  mobileAppId: string;
  platform: 'ios' | 'microsoft' | 'android';
  setSccmApplicationDetails: React.Dispatch<
    React.SetStateAction<SccmApplication | undefined>
  >;
}) {
  const {
    data: integrationData,
    loading: integrationLoading,
    error: integrationError,
    status: integrationStatus,
  } = useData<IntegrationDetailsType | undefined>(
    `/api/operations/integration/${platform}/${mobileAppId}`,
    undefined,
  );

  const {
    data,
    refetch: refetchDetailview,
    status,
  } = useData<SccmApplication | undefined>(
    `/api/operations/legacy-sccm/application-by-sim-requestid/${integrationData?.displayName}`,
    undefined,
    { enabled: integrationStatus == 'success' },
  );

  React.useEffect(() => {
    data && setSccmApplicationDetails(data);
  }, [data]);

  return (
    <div>
      {integrationStatus == 'loading' || status == 'loading' ? (
        <Skeleton.Button active={true} size={'small'} block={false} />
      ) : data !== undefined && data.resourceId ? (
        <>
          <CheckCircleTwoTone twoToneColor="#52c41a" /> {data?.displayName}
        </>
      ) : (
        <>
          <CloseCircleTwoTone twoToneColor="#eb2f96" /> n/a
        </>
      )}
    </div>
  );
}
