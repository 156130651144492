import * as React from "react"
import { Tooltip, Button } from "antd"
import { Input, Select, DatePicker, Checkbox } from "formik-antd"
import { Field as FormikField } from "formik"
import {} from "../ts-models"
import {
  ModelProperty,
  EnumDataSource,
  ApiDataSource,
  ConfigDataSource,
} from "../ts-models-custom"
import { SelectFromApi } from "./remote-select"
import { RichTextEditor } from "./richtexteditor"

export function Editor({
  property,
  name,
  disabled = false,
  referenceId,
}: {
  property: ModelProperty
  name: string
  disabled?: boolean
  referenceId?: string
}) {
  const { type } = property

  if (
    name === "description" ||
    name === "useCase" ||
    name === "functionTestDocument" ||
    name === "securityCheckDocument" ||
    name === "functionTestDescription" ||
    name === "appPermissions" ||
    name === "nftComment" ||
    name === "qaTsComment" ||
    name === "qaAoComment" ||
    name === "packagingGuide" ||
    name === "comment" ||
    name === "dataProtectionAssessmentData" ||
    name === "dataProtectionAssessmentComment"
  ) {
    return (
      <FormikField name={name}>
        {({ field }: { field: any }) => (
          <RichTextEditor field={field} disabled={disabled} />
        )}
      </FormikField>
    )
  }

  const { dataSource } = property
  if (dataSource && dataSource.type === "Enum") {
    const enumDataSource = dataSource as EnumDataSource
    return (
      <Select
        name={name}
        style={{ width: "100%" }}
        disabled={disabled}
        placeholder="(Choose...)"
      >
        {enumDataSource.values.map((v) => (
          <Select.Option key={v.key} value={v.key}>
            {v.label}
          </Select.Option>
        ))}
      </Select>
    )
  } else if (dataSource && dataSource.type === "Api") {
    const apiDataSource = dataSource as ApiDataSource
    return (
      <>
        <SelectFromApi
          //placeholder="(Choose...)"
          name={name}
          url={apiDataSource.apiUrl ? apiDataSource.apiUrl : ""}
          backingField={
            apiDataSource.backingField ? apiDataSource.backingField : ""
          }
          referenceId={referenceId}
          disabled={disabled}
        />
      </>
    )
  } else if (dataSource && dataSource.type === "Config") {
    const configDataSource = dataSource as ConfigDataSource
    return (
      <Select
        name={name}
        style={{ width: "100%" }}
        disabled={disabled}
        placeholder="(Choose...)"
      >
        {configDataSource.values.map((v) => (
          <Select.Option key={v.key} value={v.key}>
            {v.label}
          </Select.Option>
        ))}
      </Select>
    )
  }

  switch (type) {
    case "System.String":
    case "System.Int32":
      return (
        <div>
          <Input name={name} disabled={disabled} fast={true} />
        </div>
      )
    case "System.DateTime":
      return (
        <div>
          <DatePicker
            name={name}
            style={{ width: "100%" }}
            disabled={disabled}
            fast={true}
          />
        </div>
      )
    case "System.Guid":
      return (
        <div>
          <Input name={name} disabled={disabled} fast={true} />
        </div>
      )
    case "System.Boolean":
      return (
        <div>
          <Checkbox name={name} disabled={disabled} />
        </div>
      )

    default: {
      return (
        <Tooltip
          placement="right"
          title={`No editor found for field ${name} (type=${type})`}
        >
          <Button>(?)</Button>
        </Tooltip>
      )
    }
  }
}
