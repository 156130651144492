import * as React from "react"
import { ResponsivePieCanvas } from "@nivo/pie"
import styled from "styled-components"
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

export interface PieDataItem {
  id: string
  label: string
  value: number
  color: string
}

export function MyResponsivePieCanvas({
  title,
  data /* see data tab */,
}: {
  title: string
  data: PieDataItem[]
}) {
  return (
    <div>
      <h3>{title}</h3>
      <Container>
        <ResponsivePieCanvas
          data={data}
          margin={{ top: 10, right: 10, bottom: 80, left: 10 }}
          innerRadius={0.7}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          colors={{ scheme: "paired" }}
          borderColor={{ from: "color", modifiers: [["darker", 0.6]] }}
          enableArcLinkLabels={false}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor="#333333"
          legends={[
            {
              anchor: "bottom",
              direction: "column",
              justify: false,
              translateX: -120,
              translateY: 40,
              itemWidth: 80,
              itemHeight: 20,
              itemsSpacing: 0,
              symbolSize: 20,
              itemDirection: "left-to-right",
            },
          ]}
        />
        <Overlay>{data.reduce((a, b) => a + b.value, 0)}</Overlay>
      </Container>
    </div>
  )
}

const Container = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
`

const Overlay = styled.div`
  position: absolute;
  text-align: center;
  top: 83px;
  width: 300px;
  font-size: 40px;
  pointer-events: none;
`
