import React, { useState } from "react";
import { Button, FloatButton, Layout, Menu, Modal, Row } from "antd";
import { Root } from "./layout";
import logo from "./logo.svg";
import {
  AppstoreAddOutlined,
  CaretUpOutlined,
  LinkOutlined,
  QuestionOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import "antd/dist/reset.css";
import "./App.css";

import styled from "styled-components";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";

import {
  IntegrationsMasterDetailView,
  renderIntegrationsMenuItem,
} from "./integrations";

import { DashboardMasterView } from "./generic/dashboard";
import { ProfileHandler } from "./generic/profile";
import SubMenu from "antd/lib/menu/SubMenu";
import {
  CountryPortfolioMasterDetailView,
  renderCountryPortfolioMenuItem,
} from "./countryPortfolio";
import {
  MultiTargetMasterDetailView,
  renderMultiTargetMenuItem,
} from "./multiTarget";
import { OperationsDetailView, renderOperationsMenuItem } from "./operations";
import {
  OperationsIntuneView,
  renderOperationsIntuneMenuItem,
} from "./operations-intune";
import { FetchContextProvider } from "./generic/glow/actions/fetch-context";
import {
  useAuthentication,
  Status,
  AuthenticationProvider,
} from "./generic/glow/authentication";
import { QueryClient, QueryClientProvider } from "react-query";
import type { MenuProps } from "antd";
import { getMenuItem } from "./generic/getMenuItem";

import { LoadingIndicator } from "./generic/loadingIndicator";

const { Header } = Layout;

function AppBody() {
  const { status, profile } = useAuthentication();
  const [userIsLoggedIn, setUserIsLoggedIn] = useState<
    null | "logged-in" | "expired"
  >(null);
  const redirectLogin = () => {
    window.location.replace(
      `/account/signin?redirectUrl=${window.location.pathname}`
    );
  };
  let location = useLocation();
  let params = useParams();

  const [intervalValue, setIntervalValue] = useState<NodeJS.Timeout>();

  React.useEffect(() => {
    if (status == Status.loggedOut) {
      // document.location.href = `/account/signin`
      redirectLogin();
    }
    if (status == Status.loggedIn) {
      setIntervalValue(registerExpirationCheckerIntervall());
      setUserIsLoggedIn("logged-in");
    }
  }, [status]);

  async function checkIfUserIsAuthenticated(): Promise<boolean> {
    let fetchResult = await fetch("/glow/profile", {
      credentials: "same-origin",
    });

    if (fetchResult.ok) {
      let jsonResult = await fetchResult.json();
      return jsonResult.isAuthenticated;
    } else {
      return false;
    }
  }

  React.useEffect(() => {
    userIsLoggedIn == "expired" && clearInterval(intervalValue);
  }, [userIsLoggedIn, intervalValue]);

  function registerExpirationCheckerIntervall(): NodeJS.Timeout {
    let interval = setInterval(async () => {
      let status = await checkIfUserIsAuthenticated();

      setUserIsLoggedIn(status ? "logged-in" : "expired");
    }, 30000);

    return interval;
  }

  return (
    <Root>
      {/* {JSON.stringify({ params, location })} */}
      <Header className="header" style={{ display: "flex" }}>
        <Link to="/">
          <img
            src={logo}
            alt="Logo"
            style={{
              height: "35px",
              float: "left",
              marginTop: 12,
              marginRight: 30,
              marginLeft: -40,
            }}
          />
        </Link>

        <Menu
          theme="light"
          mode="horizontal"
          style={{ flexGrow: 1, flexShrink: 1 }}
          items={[
            getMenuItem(
              "Integration",
              "integration",
              <AppstoreAddOutlined />,
              renderIntegrationsMenuItem()
            ),
            getMenuItem("Deployment", "deployment", <ThunderboltOutlined />, [
              renderOperationsMenuItem(),
              renderOperationsIntuneMenuItem(),
              renderCountryPortfolioMenuItem(),
              renderMultiTargetMenuItem(),
            ]),
            getMenuItem(
              <div style={{ float: "right", textAlign: "right" }}>Help</div>,
              "help",
              <QuestionOutlined />,
              [
                getMenuItem(
                  <a
                    href="https://hcgroupnet.sharepoint.com/:b:/r/teams/DWPApplicationMgmt/Shared%20Documents/General/Transfer/_Information/GLB%20CS%20SOP%20-%20Silver%20Monkey%20software%20Management%20Framework.pdf?csf=1&web=1&e=Wmp852"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Software Management Framework
                  </a>,
                  "5",
                  <LinkOutlined size={150} />
                ),
                getMenuItem(
                  <a
                    href="https://hcgroupnet.sharepoint.com/:b:/r/teams/DWPApplicationMgmt/Shared%20Documents/General/Transfer/_Information/GLB%20CS%20SOP%20Silver%20Monkey%20Operations%20and%20Portfolio.pdf?csf=1&web=1&e=DBkdXD"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Operations &amp; Portfolio Modules
                  </a>,
                  "6",
                  <LinkOutlined size={150} />
                ),
                getMenuItem(
                  <a
                    href="https://hcgroupnet.sharepoint.com/:b:/r/teams/DWPApplicationMgmt/Shared%20Documents/General/Transfer/_Information/GLB%20CS%20SOP%20-%20Silver%20Monkey%20%E2%80%93%20Multi%20Target%20Deployment.pdf?csf=1&web=1&e=LTEihg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Multi Target Deployment
                  </a>,
                  "7",
                  <LinkOutlined size={150} />
                ),
              ]
            ),
          ]}
        ></Menu>
        <span
          style={{
            float: "left",
          }}
        >
          <ProfileHandler />
        </span>
      </Header>
      {userIsLoggedIn == "logged-in" ? (
        <React.Fragment>
          <Routes>
            <Route path={`/`} element={<DashboardMasterView />}></Route>
          </Routes>
          <IntegrationsMasterDetailView />
          <CountryPortfolioMasterDetailView />
          <MultiTargetMasterDetailView />
          <OperationsDetailView />
          <OperationsIntuneView />
        </React.Fragment>
      ) : userIsLoggedIn == "expired" ? (
        <Modal
          title="Session expired"
          open={true}
          closable={false}
          footer={[
            <Button key="submit" type="primary" onClick={() => redirectLogin()}>
              Login
            </Button>,
          ]}
        >
          <p>Your session is expired.</p>
          <p>Please login to continue working.</p>
        </Modal>
      ) : (
        <div>
          <LoadingIndicator />
        </div>
      )}
      <Footer>
        <VersionInfo />
        <FloatButton.BackTop>
          <div className="button-backtop">
            <CaretUpOutlined /> Back to top
          </div>
        </FloatButton.BackTop>
      </Footer>
    </Root>
  );
}

const Logo = styled.div`
  background: rgba(0, 0, 0, 0.65);
  height: 50px;
  color: white;
  text-align: center;
  padding-top: 8px;
  font-size: 20px;
`;

const queryClient = new QueryClient();

export function App() {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <FetchContextProvider>
          <AuthenticationProvider>
            <AppBody />
          </AuthenticationProvider>
        </FetchContextProvider>
      </QueryClientProvider>
    </Router>
  );
}

// const Header = styled.div`
//   display: flex;
//   background: white;
//   justify-content: flex-end;
//   padding: 8px;
// `

const HeaderProfile = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
  & > * {
    margin-right: 5px;
  }
`;

export const BUILD_ID = process.env["REACT_APP_BUILD_BUILDID"];
export const BUILD_NUMBER = process.env["REACT_APP_BUILD_BUILDNUMBER"];
export const COMMIT_ID = process.env["REACT_APP_BUILD_SOURCEVERSION"];

function VersionInfo() {
  return <Container>{BUILD_ID || "v2.4.0"}</Container>;
}

const Footer = styled(Row)`
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  align-self: center;
  text-align: right;
  color: #a9a9a9;
  margin-right: 5px;
`;
