import * as React from "react"
import {
  Country,
  MultiTargetRequest,
  OsdCountryIndividualOption,
} from "../ts-models"
import { Space, Table } from "antd"

import { useState } from "react"
import { GetField } from "../generic/field"
import { useFormikContext } from "formik"
import { Select, Radio, Input } from "formik-antd"
import { useData } from "../generic/glow/actions/use-data"

export const Field = GetField<MultiTargetRequest>()

function CountryRadios({
  radioName,
  pkgType,
  pkgID,
  sccmPackagePrograms,
  index,
  isDeployRequest,
}: {
  radioName: string
  pkgType: "PkgJob" | "other"
  pkgID: string
  sccmPackagePrograms: string[] | null
  index: number
  isDeployRequest: boolean
}) {
  const RadioGroup = Radio.Group

  return (
    <span>
      <RadioGroup
        name={`osdCountryIndividualOptions.${index}.osdCountryLayer`}
        options={
          isDeployRequest
            ? [
                { label: "Ignore", value: "ignore" },
                { label: "Country Core", value: "countryCore" },
                { label: "Country Optional", value: "countryOptional" },
              ]
            : [
                { label: "Ignore", value: "ignore" },

                { label: "Remove", value: "removeAll" },
              ]
        }
      ></RadioGroup>
      {pkgType === "PkgJob" && isDeployRequest && (
        <Select
          style={{ width: 150 }}
          size="small"
          name={`osdCountryIndividualOptions.${index}.osdPackageProgram`}
        >
          {sccmPackagePrograms?.map((v) => (
            <Select.Option key={v} value={v}>
              {v}
            </Select.Option>
          ))}
        </Select>
      )}
    </span>
  )
}

export function OsdTable({
  pkgType,
  pkgID,
  sccmPackagePrograms,
  isDeployRequest,
}: {
  pkgType: "PkgJob" | "other"
  pkgID: string
  sccmPackagePrograms: string[] | null
  isDeployRequest: boolean
}) {
  const columns = [
    {
      title: "Country",
      dataIndex: "",
      width: "30%",
      render: (
        value: any,
        record: OsdCountryIndividualOption,
        index: number,
      ) => <span>{record.countryDisplayName}</span>,
    },

    {
      title: "Application Layer",
      dataIndex: "",
      key: "x",
      width: "70%",
      render: (
        value: any,
        record: OsdCountryIndividualOption,
        index: number,
      ) => (
        <CountryRadios
          radioName={record.id || ""}
          pkgType={pkgType}
          pkgID={pkgID}
          isDeployRequest={isDeployRequest}
          sccmPackagePrograms={sccmPackagePrograms}
          index={index}
        />
      ),
    },
  ]

  const { values } = useFormikContext<MultiTargetRequest>()

  const [programSelectValue, setProgramSelectValue] = useState("")

  const { data: countries } = useData<Country[]>(`/api/countries/list`, [])

  const { Option } = Select

  return (
    <Space direction="vertical">
      <div>
        <Field name="osdCountryLayer" />
      </div>
      {pkgType === "PkgJob" &&
        values.osdCountryLayer != "individual" &&
        values.osdCountryLayer != "removeAll" && (
          <div>
            Select Program{" "}
            <Select
              //defaultValue={programSelectValue}
              style={{ width: 150 }}
              size="small"
              onChange={(e: any) => {
                setProgramSelectValue(e)
              }}
              //value={programSelectValue}
              name="osdPackageProgram"
            >
              {sccmPackagePrograms?.map((v) => (
                <Select.Option key={v} value={v}>
                  {v}
                </Select.Option>
              ))}
            </Select>
          </div>
        )}
      {values.osdCountryLayer == "individual" && (
        <Table<OsdCountryIndividualOption>
          columns={columns}
          //expandedRowRender={record => <p style={{ margin: 0 }}>{record.description}</p>}
          dataSource={values.osdCountryIndividualOptions}
          size="small"
          pagination={false}
          scroll={{ y: 240 }}
          loading={countries == null}
          rowKey="id"
        />
      )}
    </Space>
  )
}
