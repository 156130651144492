import { Table } from "antd"
import { DateTime } from "luxon"
import * as React from "react"
import { useNavigate } from "react-router"
import { useData } from "../../generic/glow/actions/use-data"
import {
  defaultWin10ClassicPlatformApprovalListDto,
  Win10ClassicPlatformApprovalListDto,
} from "../../ts-models"
import { QueryResult } from "../../ts-models-custom"

export function PlatformApprovalTable({
  integrationId,
}: {
  integrationId: string
}) {
  const url = `/api/integrations/win10-classic-platformapproval/list?OrderByColumn=DisplayName&OrderDirectionIsAsc=false&SearchString=&take=30&skip=0&integrationId=${integrationId}`
  const { data, loading, error } = useData<
    QueryResult<Win10ClassicPlatformApprovalListDto>
  >(url, {
    count: 0,
    value: [defaultWin10ClassicPlatformApprovalListDto],
  })
  const navigate = useNavigate()

  return (
    <Table<Win10ClassicPlatformApprovalListDto>
      loading={loading}
      bordered={false}
      size="small"
      style={{ flex: 1 }}
      onRow={(record) => ({
        onClick: () =>
          navigate(
            `/integrations/win10-classic-platformapproval/${record.id}/${integrationId}`,
          ),
      })}
      rowKey={(row) => row.id}
      // components={{
      //   body: {
      //     row: (props: any) => <HighlightableRow path="/MobileAppIntegration/" {...props} />,
      //   },
      // }}
      columns={[
        {
          title: "Platform",
          key: "approvalPlatform.displayName",
          render: (record: Win10ClassicPlatformApprovalListDto) => (
            <span>
              {record.approvalPlatform?.displayName == null
                ? "no platform"
                : record.approvalPlatform?.displayName == "  "
                ? "unknown"
                : record.approvalPlatform?.displayName}
            </span>
          ),
        },
        {
          title: "Status",
          key: "currentStateName",
          render: (record: Win10ClassicPlatformApprovalListDto) => (
            <span>{record.workflowInstance?.currentStateName}</span>
          ),
        },
        {
          title: "Create date",
          key: "createdAt",
          render: (record: Win10ClassicPlatformApprovalListDto) => (
            <span>
              {DateTime.fromISO(record.createdAt).toLocaleString(
                DateTime.DATE_MED_WITH_WEEKDAY,
              )}
            </span>
          ),
        },
        {
          title: "Update date",
          key: "updatedAt",
          render: (record: Win10ClassicPlatformApprovalListDto) => (
            <span>
              {DateTime.fromISO(record.updatedAt).toLocaleString(
                DateTime.DATE_MED_WITH_WEEKDAY,
              )}
            </span>
          ),
        },
      ]}
      dataSource={data.value ? data.value : []}
      pagination={
        data && data.count > 10
          ? {
              pageSize: 10,
            }
          : false
      }
    />
  )
}
