import * as React from "react"
import { Route, useNavigate, Routes, Link } from "react-router-dom"
import { PageHeader } from '@ant-design/pro-layout';
import { Layout, Menu } from "antd";
import { PartitionOutlined } from "@ant-design/icons"
import styled from "styled-components"
import { constants } from "./constants"
import { useState } from "react"

import { useParams } from "react-router"
import { MultiTargetListView } from "./list"
import { MultiTargetDetailView } from "./detail"
import { MultiTargetRequestDetailView } from "./detail_request"
import { MenuItem, getMenuItem } from "../generic/getMenuItem";

export function renderMultiTargetMenuItem() : MenuItem {

  return getMenuItem(<Link to={paths.main}>
       {constants.linkName}
    </Link>, paths.main, <PartitionOutlined size={150} />)

}

const { paths } = constants
const { Content } = Layout

export function MultiTargetMasterDetailView() {
  const [siderColapsed, setSiderColapsed] = useState(false)

  return (
    <Routes>
      <Route
        path={`${paths.main}/*`}
        element={
          <Layout>
            <Layout style={{ flex: 1, flexDirection: "column" }}>
              <Content style={{ flex: 1 }}>
                <Routes>
                  <Route path={paths.id} element={<MultiTargetDetailView />} />

                  <Route path={paths.list} element={<MultiTargetListView />} />
                  <Route
                    path={paths.main_request_id}
                    element={<MultiTargetRequestDetailView />}
                  />
                </Routes>
              </Content>
            </Layout>
          </Layout>
        }
      ></Route>
    </Routes>
  )
}
