import * as React from 'react';
import { AadUser, SccmUser } from '../ts-models';
import { Checkbox, Skeleton, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { SelectedUsersHandler } from './list_users';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useData } from '../generic/glow/actions/use-data';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';

export function UserTable({
  selectedUsers,
  setSelectedUsers,
  platform,
  mecmUsers,
  setMecmUsers,
}: {
  selectedUsers: AadUser[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<AadUser[]>>;
  platform: 'ios' | 'microsoft' | 'android';
  mecmUsers: SccmUser[];
  setMecmUsers: React.Dispatch<React.SetStateAction<SccmUser[]>>;
}) {
  const [columns, setColumns] = React.useState<ColumnsType<AadUser>>([]);

  const columnsBasic: ColumnsType<AadUser> = [
    {
      title: '',
      dataIndex: 'key',
      key: 'key',
      render: (key, row) => (
        <Checkbox
          onChange={(e: CheckboxChangeEvent) =>
            setSelectedUsers((oldVal) =>
              e.target.checked
                ? [...oldVal, row]
                : [...oldVal.filter((x) => x.id !== row.id)],
            )
          }
          checked={true}
        ></Checkbox>
      ),
      width: 0.05,
    },
    {
      title: 'User',
      dataIndex: 'userName',
      key: 'userName',
      render: (key, row) => <>{row.displayName}</>,
      width: 0.2,
      ellipsis: true,
    },
    {
      title: `Intune`,
      dataIndex: 'Intune',
      key: 'upn',
      render: (key, row) => (
        <>
          {' '}
          <CheckCircleTwoTone twoToneColor="#52c41a" /> {row.userPrincipalName}
        </>
      ),
      width: 0.4,
      ellipsis: true,
    },
  ];

  const columnsMecm: ColumnsType<AadUser> = [
    {
      title: `MECM (${mecmUsers.length})`,
      key: 'mecm',
      dataIndex: 'mecm',
      render: (key, row) => (
        <>
          {row.userPrincipalName && (
            <MecmUser
              upn={row.userPrincipalName}
              mecmUsers={mecmUsers}
              setMecmUsers={setMecmUsers}
            />
          )}
        </>
      ),
      width: 0.3,
      ellipsis: true,
    },
  ];

  React.useEffect(() => {
    if (platform == 'microsoft') {
      //setColumns([...columnsBasic, ...columnsMecm]);
      setColumns([...columnsBasic]);
    } else {
      setColumns([...columnsBasic]);
    }
  }, [platform]);

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={selectedUsers}
      pagination={false}
      scroll={{ y: 240 }}
    />
  );
}

export function MecmUser({
  upn,
  mecmUsers,
  setMecmUsers,
}: {
  upn: string;
  mecmUsers: SccmUser[];
  setMecmUsers: React.Dispatch<React.SetStateAction<SccmUser[]>>;
}) {
  const {
    data,
    refetch: refetchDetailview,
    status,
  } = useData<SccmUser | undefined>(
    `/api/operations/legacy-sccm/user-by-upn/${upn}`,
    undefined,
  );

  React.useEffect(() => {
    if (data) {
      setMecmUsers(
        mecmUsers.filter((x) => x.resourceId !== data.resourceId).length == 0
          ? [...mecmUsers, data]
          : [...mecmUsers],
      );
    }
  }, []);

  return (
    <div>
      {status == 'loading' ? (
        <Skeleton.Button active={true} size={'small'} block={false} />
      ) : data !== undefined && data.resourceId ? (
        <>
          <CheckCircleTwoTone twoToneColor="#52c41a" /> {data?.samAccountName}
        </>
      ) : (
        <>
          <CloseCircleTwoTone twoToneColor="#eb2f96" /> n/a
        </>
      )}
    </div>
  );
}
